import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Patients_Model } from 'src/app/Models/Patients_Model';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { DoctorService } from 'src/app/ServicesAPis/Doctor/doctor.service';
import { PatientsService } from 'src/app/ServicesAPis/Doctor/patients.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';

@Component({
  selector: 'app-doctor-patient',
  templateUrl: './doctor-patient.component.html',
  styleUrls: ['./doctor-patient.component.css']
})
export class DoctorPatientComponent implements OnInit {
  isLoggedIn = false;
  showside = false;
  patientList:any[]=[]
  id:string;

  constructor(
    private tokenStorageService: TokenStorageService ,  
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,     
    private router: Router,
    private patientService: PatientsService,
    private  imageService:UploadImageService
  ) { }

  ngOnInit(): void {
    
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
    this.id = this.route.snapshot.params['id'];

    this.retrieveAllPatient(this.id);

  }
  retrieveAllPatient(id): void {
    
    this.patientService.getAllPatients(id)
    .subscribe(
      response => {
        this.patientList = response;
      },
      error => {
        //console.log(error);
      });
  }
  deletepatient(id) {
    this.patientService.deletePatient(id).subscribe((data:any)=>{
    this.retrieveAllPatient(this.id);

  });
  }

  setpatient(id:string , patient:Patients_Model){
      this.patientService.setPatent(patient);
      this.router.navigate(['/Patient-Editions',id]);

  }

}
