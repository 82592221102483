<div class="row mt-5" style="justify-content: center; text-align: right;"  *ngIf="showside">
    <div class="col-lg-11">
        <div class="container emp-profile" *ngIf="!isAddMode" dir="rtl">
            <form  >
                <div class="row">
               
                    <div class="col-md-6">
                        <div class="profile-head">
                                    <h5>
                                      الاسم كاملا  
                                    </h5>
                                    
                            <ul class="nav nav-tabs mt-3" id="myTab" role="tablist" style="width: 125%;" >
                                <li class="nav-item">
                                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">البيانات الشخصيه</a>
                                </li>
                              
                                <li class="nav-item">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">البيانات الخاصه بالكشف</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="tour-tab" data-toggle="tab" href="#tour" role="tab" aria-controls="tour" aria-selected="false">البيانات السفر</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                   
                </div>
                <div class="row">
                    
                    <div class="col-md-8">
                        <div class="tab-content profile-tab" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>بلد الاقامه</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{formsection.patient.country}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>الجنسيه </label>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{formsection.patient.nationality}}</p>
                                    </div>
                                </div>    
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>الايميل</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{formsection.patient.email}}</p>
                                    </div>
                                </div>   
                                <div class="row">
                                            <div class="col-md-6">
                                                <label>رقم الهاتف</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{{formsection.patient.phoneNumber}}</p>
                                            </div>
                                        </div>
                                       
                                    
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>يوم الميلاد</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{{formsection.patient.birthDate}}</p>
                                            </div>
                                        </div>
                                        
                            </div>
                            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>الخدمه المطلوبه</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{formsection.serviceName}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>عدد المرضى</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{formsection.numberOfPatients}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>وصف الحاله</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{formsection.patientStatus}}</p>
                                    </div>
                                </div>
                              
                               
                 
                    </div>
                            <div class="tab-pane fade" id="tour" role="tabpanel" aria-labelledby="tour-tab">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label> الاقامه</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p *ngIf="formsection.isResident">نعم اريد </p>
                                        <p *ngIf="!formsection.isResident">لا اريد </p>
    
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>تذاكر الطيران</label>
                                    </div>
                                    <div class="col-md-6">
    
                                        <p *ngIf="formsection.flightTickets">نعم اريد </p>
                                        <p *ngIf="!formsection.flightTickets">لا اريد </p>                            
                                        </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>مواصلات داخليه</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p *ngIf="formsection.domescticTransportation">نعم اريد </p>
                                        <p *ngIf="!formsection.domescticTransportation">لا اريد </p> 
                                                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>جوله سياحيه</label>
                                    </div>
                                    <div class="col-md-6">
    
                                        <p *ngIf="formsection.tour">نعم اريد </p>
                                        <p *ngIf="!formsection.tour">لا اريد </p>                                 </div>
                                </div>
                              
                 
                            </div>
                           
                        </div>
                    </div>
                </div>
    
    
            </form> 
                 
        </div>
    <div class="container emp-profile" *ngIf="isAddMode">
        <div class="col-sm-8">
            <div class="search-box" style="width:inherit">
                
                <input  type="text" class="form-control"
                 placeholder="Search with  Service Name, Phone Number, Patient Name "
                 id="speciesNameSearch"
                  name="speciesNameSearch" 
                (keyup.enter)="getSearchResult()"
                 [(ngModel)]="speciesName">
                <a class="btn primary">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </a>
            </div>
        </div>
        <table class="table table-striped table-hover table-bordered" style="text-align: left;">
            <thead>
                <tr>
                    <th>#</th>
                    
                    <th>Name </th>
                    <th>Phone </th>
                    <th>Service </th>



                   
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of formList let i= index">
                    <td>{{i+1}}</td>
                    <td>{{item.patient.name}}</td>
                    <td>{{item.patient.phoneNumber}}</td>
                    <td>{{item.serviceName}}</td>

                   
                    <td>
                      
                        <div style="display: inline-flex;">

                        <a [routerLink]="['/Tour-Patient', item.appointmentID]"  class="btn view" title="view" data-toggle="tooltip"><i class="material-icons">&#xE417;</i></a>
                        <a (click)="deleteitem(item.appointmentID)" class="btn delete" title="Delete" data-toggle="tooltip"><i class="material-icons">&#xE872;</i></a>
                        </div>
                    </td>
                </tr>
               
               
                        
            </tbody>
        </table>
             
    </div>
    </div>
</div>