import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Famouse_Model } from 'src/app/Models/Famouse_Model';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { FamouseService } from 'src/app/ServicesAPis/Famouse/famouse.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';
import { UploadImagesService } from 'src/app/ServicesAPis/files/upload-images.service';
import { RewiewService } from 'src/app/ServicesAPis/HappyPatient/rewiew.service';

@Component({
  selector: 'app-celebrity-edition',
  templateUrl: './celebrity-edition.component.html',
  styleUrls: ['./celebrity-edition.component.css']
})
export class CelebrityEditionComponent implements OnInit {
  path1:string
  isLoggedIn = false;
  imgpath:string;
  showside = false;
  isAddMode: boolean;
  id:string;
  form :FormGroup;
  submitted = false;
  famousSectionImages: FormArray;
  famouswSection:Famouse_Model

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService ,
    private famouseService : FamouseService,
    private  imageService:UploadImageService,
    private imagesServices:UploadImagesService

  ) {}


  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        name: ['', Validators.required],
        imagePath: ['', Validators.required],

        insertDate: ['2022-01-29T20:16:07.498Z'],

        famousSectionImages: this.formBuilder.array([ this.createDetails() ])
      }
    );
  
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    // Get the data by Id
    if (!this.isAddMode) {
      // this.form.get('imagePath').setValue(File, {emitModelToViewChange: false});

      this.famouseService.getFamouseByID(this.id)
          .pipe()
          .subscribe(x => this.famouswSection=x);
  }
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  onSubmit(): void {
    this.submitted = true;

  

    if (this.form.invalid) {
      return;
    }

    if (this.isAddMode) {
      // alert("here")
      this.creatsection();

  } else {
    // alert("here update")

      this.updateSection();
  } 
 }
 creatsection():void{
  // alert("here create");
  this.form.value.imagePath=this.path1;

  //console.log(this.form.value)
  this.famouseService.addFamouse(this.form.value).subscribe((data:any)=>{
    // alert("added")
    this.router.navigate(['/Celebrities']);
 });
}
 private updateSection():void{

 }
  createDetails(): FormGroup {
    return this.formBuilder.group({
      imagePath: ''
      
    });
  }
  addSection(): void {
    this.famousSectionImages = this.form.get('famousSectionImages') as FormArray;
    this.famousSectionImages.push(this.createDetails());
  }
  uploadFile1= (files) => {
    //console.log(files)
    this.imageService.uploadFile(files).subscribe((data:any)=>{
     this.path1=data.path;
      this.form.value.imagePath=data.path
  
  
    });
  }
  get imagePath() {
    return this.form.get('famousSectionImages');
  }
  getval:any
  uploadFile2= (files , i) => {
   
 //console.log(files)
      this.imageService.uploadFile(files).subscribe((data:any)=>{
        
        // alert("add images")
        //console.log(data.path)
        this.imagePath.value[i].imagePath=data.path


    
      });
    }
}
