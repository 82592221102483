
<div class="row mt-5" style="justify-content: center; "  *ngIf="showside">
  <div class="col-xl-11 ">

    

    <div class="container-xl">
        <div class="table-responsive">
            <div class="table-wrapper">
                <div class="table-title">
                    <div class="row">
                        <div class="col-sm-6" style="text-align: initial;"><h2>Status <b>Details</b></h2></div>
                        
                        <div class="col-sm-5">
                            <a class="btn btn-info add-new " href="/Before-After-Edition" style="margin-left: auto;color: #fff;"> 
                                <i class="fa fa-plus"></i>                
                                  Add Status
                
                            </a>
                        </div>
                    </div>
                </div>
                <table class="table table-striped table-hover table-bordered" style="text-align: initial;">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th class="link">
                                Before   
                                <a (click)="retrieveAllStatusAsc()" class="btn"> <i class="fa fa-arrow-up" aria-hidden="true"></i></a>
                                <a (click)="retrieveAllStatus()" class="btn"> <i class="fa fa-arrow-down" aria-hidden="true"></i></a>
                            
                            </th>
                            <th>After </th>
                            <th>Description </th>
                            
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let status of statusList let i= index">
                            <td>{{i+1}}</td>
                            <td><img [src]="status.beforeImagePath" width="150"></td>
                            <td><img [src]="status.afterImagePath" width="150"></td>

                            <td>{{status.description}}</td>
                            
                            <td>
                               
                            
                                <div style="display: inline-flex;">
                                <!-- <a (click)="updatestatus(status.serviceImagesID , status)" class="btn edit" title="Edit" data-toggle="tooltip"><i class="material-icons">&#xE254;</i></a> -->
                                <a (click)="deleteStatus(status.serviceImagesID)" class="btn delete" title="Delete" data-toggle="tooltip"><i class="material-icons">&#xE872;</i></a>
                                </div>
                            </td>
                        </tr>
                       
                       
                                
                    </tbody>
                </table>
           
            </div>
        </div>  
    </div>
</div>
</div>