import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Doctor_Model } from 'src/app/Models/Doctor_Model';
import { Patients_Model } from 'src/app/Models/Patients_Model';
import { environment } from '../../../environments/environment';


const baseUrl=environment.baseUrl+'admin/v1/doctorpatient';

@Injectable({
  providedIn: 'root'
})
export class PatientsService  {

  patient:Patients_Model

  constructor(private http: HttpClient) { }

  ngOnInit() {          
}
addPatient(patient : Patients_Model)
{
  return this.http.post(baseUrl,patient);

}
// http://mohamed555dsa-001-site1.btempurl.com/admin/v1/doctorpatient/doctor/b28cb757-c2c4-4571-84e5-9203c49e418d

getAllPatients(doctorid:any): Observable<any> {
  return this.http.get(`${baseUrl}/doctor/${doctorid}`);
}

setPatent(patient:Patients_Model){
this.patient=patient

}
getPatent(){
  return this.patient
  }
updatePatient(id:string,patient:Patients_Model){
  return this.http.put(`${baseUrl}/${id}`,patient);
}
deletePatient(id:any): Observable<any> {
  return this.http.delete(`${baseUrl}/${id}`);
}




}
