import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Status_Model } from 'src/app/Models/Status_Model';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';
import { GalleryService } from 'src/app/ServicesAPis/Gallery/gallery.service';
import { StatusService } from 'src/app/ServicesAPis/Status/status.service';

@Component({
  selector: 'app-beforeandafterstatus',
  templateUrl: './beforeandafterstatus.component.html',
  styleUrls: ['./beforeandafterstatus.component.css']
})
export class BeforeandafterstatusComponent implements OnInit {
  AddClicked: Boolean = false;
  index:number;
  isLoggedIn = false;
  showside = false;
  username?: string;
  form :FormGroup;
  submitted = false;
  statusList:any[]=[];
  statusListData:Status_Model[]=[];
  constructor(
    private tokenStorageService: TokenStorageService ,  
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,     
    private router: Router,
    private statusServices: StatusService,
    private  imageService:UploadImageService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
     

      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
   
    this.retrieveAllStatus();

  }
  retrieveAllStatus(): void {
    
    this.statusServices.getAllStatus()
    .subscribe(
      response => {
        this.statusList = response;
        
      },
      error => {
        //console.log(error);
      });
  }
  retrieveAllStatusAsc(): void {
    
    this.statusServices.getAllStatusAsc()
    .subscribe(
      response => {
        this.statusList = response;
        
      },
      error => {
        //console.log(error);
      });
  }
  deleteStatus(id) {
    this.statusServices.deleteStatus(id).subscribe((data:any)=>{
    this.retrieveAllStatus();

  });
  }
  updatestatus(id , item){
    
    this.statusServices.getStatusByID(item);
    this.router.navigate(['/Before-After-Edition', id]);

    // [routerLink]="['/Before-After-Edition', status.serviceImagesID]" 
  }
}
