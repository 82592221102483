import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/ServicesAPis/Admin/admin.service';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';

@Component({
  selector: 'app-admin-aditions',
  templateUrl: './admin-aditions.component.html',
  styleUrls: ['./admin-aditions.component.css']
})
export class AdminAditionsComponent implements OnInit {

  isAddMode: boolean;
  id:string;
  form :FormGroup;
  submitted = false;
  isLoggedIn = false;
  showside = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService ,
    private adminService : AdminService

  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
    this.form = this.formBuilder.group(
      {
        userName: ['', Validators.required],
        email: ["",Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
        password: ['', Validators.required],


      }
    );
  
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    if (!this.isAddMode) {
      // this.form.get('imagepath').setValue(File, {emitModelToViewChange: false});

        this.adminService.getAdminByID(this.id)
            .pipe()
            .subscribe(x => {this.form.patchValue(x);});
      
  }

  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;



    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    if (this.isAddMode) {

        this.creatAdmin();

    } else {
        this.updateAdmin();
    }
}


List =[]
index : number;
private creatAdmin() :void{


  this.adminService.addAdmin(this.form.value).subscribe((data:any)=>{
    this.router.navigate(['/Admin'])
   
  });

 


}

private updateAdmin():void{

  this.adminService.updateAdmin(this.id,this.form.value).subscribe((data:any)=>{
    this.router.navigate(['/Admin'])
   
  });

}
}
