import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Reviews_Model } from 'src/app/Models/Reviews_Model';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';
import { RewiewService } from 'src/app/ServicesAPis/HappyPatient/rewiew.service';


@Component({
  selector: 'app-reviewedition',
  templateUrl: './reviewedition.component.html',
  styleUrls: ['./reviewedition.component.css']
})
export class RevieweditionComponent implements OnInit{
  isAddMode: boolean;
  id:string;
  form :FormGroup;
  submitted = false;
  isLoggedIn = false;
  imgpath:string;
  showside = false;
  imagesList:any[]=[];
  reviewsListData:Reviews_Model[]=[];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService ,
    private reviewService : RewiewService,
    private  imageService:UploadImageService
  ) {}


  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
    this.form = this.formBuilder.group(
      {
        imagePath: ['', Validators.required],
        name: ['', Validators.required],
        job: ['', Validators.required],
        comment: [
          '',
          [
            Validators.required,
            Validators.minLength(20),
            // Validators.maxLength(300)
          ]
        ]
        
      }
    );
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    if (!this.isAddMode) {
      this.form.get('imagePath').setValue(File, {emitModelToViewChange: false});

        this.reviewService.getReviewByID(this.id)
            .subscribe(x => {

              this.form.patchValue(x);  
          });
      
  }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.isAddMode) {

      this.creatReview();

  } else {
      this.updateReview();
  } 
 }
  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }
  private creatReview():void{
      

    this.reviewsListData.push(this.form.value)
    this.reviewService.addReview(this.reviewsListData).subscribe((data:any)=>{
      this.router.navigate(['/Happy-Patients']);
      
    });

  }
  private updateReview():void{
    this.reviewService.updateReview(this.id,this.form.value).subscribe((data:any)=>{
      this.router.navigate(['/Happy-Patients']);
     
    });
  }
  uploadFile= (files) => {
    this.imageService.uploadFile(files).subscribe((data:any)=>{
      this.form.value.imagePath=data.path

        


    });
    }
}