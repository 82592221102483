<div class="row mt-5" style="justify-content: center; " *ngIf="showside">
    <div class="col-xl-12 ">
  
        <div class="card easion-card">
            <div class="container-xl">
                <div class="table-responsive">
                    <div class="table-wrapper">
                        <div class="table-title">
                            <div class="row">
                                <div class="col-sm-8"><h2>Patients <b>Details</b></h2></div>
                                <div class="col-sm-4">
                                    <a type="button" class="btn btn-info add-new" href="/Patient-Editions"><i class="fa fa-plus"></i> Add patient </a>
                                </div>
                           </div>
                        </div>
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                   <th>Patient Image</th>

                                    <th>Doctor With Patient</th>
                                    
                                    <th>caught</th>

                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let patient of patientList let i= index">
                                    <td>{{i+1}}</td>
                                    <td><img [src]="patient.patientImagePath" width="100"></td>
                                    <td><img [src]="patient.patientWithDoctorImagePath" width="100"></td>

                                    <td>{{patient.caught}}</td>
                                   
                                    <td>
                                        <!-- <a href="#" class="view" title="View" data-toggle="tooltip"><i class="material-icons">&#xE417;</i></a> -->
                                        <a (click)="setpatient(patient.doctorPatientID , patient)" class="edit" title="Edit" data-toggle="tooltip"><i class="material-icons">&#xE254;</i></a>
                                        <a  class="btn" (click)="deletepatient(patient.doctorPatientID)" class="delete" title="Delete" data-toggle="tooltip"><i class="material-icons">&#xE872;</i></a>
                                    </td>
                                </tr>
                                  
                            </tbody>
                        </table>
                        <!-- <div class="clearfix">
                            <div class="hint-text">Showing <b>5</b> out of <b>25</b> entries</div>
                            <ul class="pagination">
                                <li class="page-item disabled"><a href="#"><i class="fa fa-angle-double-left"></i></a></li>
                                <li class="page-item"><a href="#" class="page-link">1</a></li>
                                <li class="page-item"><a href="#" class="page-link">2</a></li>
                                <li class="page-item active"><a href="#" class="page-link">3</a></li>
                                <li class="page-item"><a href="#" class="page-link">4</a></li>
                                <li class="page-item"><a href="#" class="page-link">5</a></li>
                                <li class="page-item"><a href="#" class="page-link"><i class="fa fa-angle-double-right"></i></a></li>
                            </ul>
                        </div> -->
                    </div>
                </div>  
            </div> 
        </div>
  
  
  </div>
  </div>
  
  
  
  
  
  
  