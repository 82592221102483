import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Admin_Model } from 'src/app/Models/Admin_Model';
import { environment } from '../../../environments/environment';


const baseUrl=environment.baseUrl+'admin/v1/admin';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  ngOnInit() {          
}
addAdmin(admin : Admin_Model): Observable<any> 
{
  return this.http.post(baseUrl,admin);
} 
getAllAdmins(): Observable<any> {
  return this.http.get<any>(baseUrl);
}

deleteAdmin(id:any): Observable<any> {
  return this.http.delete(`${baseUrl}/${id}`);
}
updateAdmin(id:any,admin:any): Observable<any> {
  return this.http.put(`${baseUrl}/${id}`,admin);
}
getAdminByID(id:any): Observable<any> {
  return this.http.get(`${baseUrl}/${id}`);
}


}
