<div class="row mt-5" style="justify-content: center; " *ngIf="showside">
  <div class="col-xl-8 ">
    <div class="card easion-card">
      <div class="card-header">
        <div class="easion-card-icon">
          <i class="fas fa-chart-bar"></i>
        </div>
        <div class="easion-card-title">Content</div>


      </div>
      <div class="card-body ">

        <div class="register-form" style="text-align: right;">

          <div class="form-group">
            <label> عنوان المقال</label>
            <div>
              {{detailsList.title}}
            </div>

          </div>

          <div class="form-group">
            <label> : كاتب المقال</label>
            <div>
              {{detailsList.writter}}
            </div>
          </div>


          <div class="form-group">
            <label> : الصوره الاساسيه للمقال </label>
            <p class="mt-3">
              <img [src]="detailsList.imagePath" alt="" width="100">
            </p>
          </div>

          <div class="form-group">
            <label> : أجزاء المقال </label>
            <div class="dtyle mt-3" *ngFor="let section of detailsList.blogDetails; let i=index ">
              <h4 style="padding: 10px; background-color: lavender;">
                الجزء
                {{i+1}} 
                من المقال
                
                
              </h4>
              <div class="form-group">

                <label> : عنوان الجزء </label>
                <div>
                  {{section.title}}
                </div>
              </div>
              <div class="form-group">

                <label> : وصف  </label>
                <div>
                  {{section.discreption}}
                </div>
              </div>
              <div class="form-group">
                <label> : الصوره   </label>
                <p class="mt-3">
                  <img [src]="section.imagePath" alt="" width="100">
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>





</div>
