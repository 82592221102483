<div class="row mt-5" style="justify-content: center; " *ngIf="showside">
  <div class="col-xl-8 ">
      <div class="card easion-card" *ngIf="!isAddMode">
        <div class="card-header">
          <div class="easion-card-icon">
              <i class="fas fa-chart-bar"></i>
          </div>
          <div class="easion-card-title">Edit</div>
      

      </div>
          <div class="card-body ">
              
              <div class="register-form">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                      
                   
                  <div class="form-group">
                    <label>اسم الشخص المعلق</label>
                    <input
                      type="text"
                      formControlName="name"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.name.errors }"

                    />
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                      <div *ngIf="f.name.errors.required">الاسم مطلوب</div>
                    </div>
                  </div>
                 
                  <div class="form-group">
                    <label>المهنة</label>
                    <input
                      type="text"
                      formControlName="job"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.job.errors }"

                    />
                    <div *ngIf="submitted && f.job.errors" class="invalid-feedback">
                      <div *ngIf="f.job.errors.required">المهنة مطلوب</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label> : التعليق</label>
                    <textarea 
                    type="text"
                    formControlName="comment"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.comment.errors }"
                    cols="20" rows="5">
                  </textarea>
                 
                    <div *ngIf="submitted && f.comment.errors" class="invalid-feedback">
                      <div *ngIf="f.comment.errors.required">require </div>
                    </div>
                  </div>
                 
                  <div class="form-group">
                    <label>: الصوره الشخصيه للمعلق   </label>
                    <input id="upload" type="file"   class="form-control border-0" #file type="file" 
                    (change)="uploadFile(file.files)"  required 
                    formControlName="imagePath"
                /> 
                    <div *ngIf="submitted && f.imagePath.errors" class="invalid-feedback">
                      <div *ngIf="f.imagePath.errors.required">Image is Required</div>
                    </div>
                  </div>
                
                 
               
                
          
                  <div class="form-group">
                    <button type="submit" class="btn btn-primary mr-5">Update</button>
                
                  </div>
                </form>
                </div>
          </div>
      </div>
  
      <div class="card easion-card" *ngIf="isAddMode">
          <div class="card-header">
            <div class="easion-card-icon">
                <i class="fas fa-chart-bar"></i>
            </div>
            <div class="easion-card-title">Add Admin</div>
        

        </div>
            <div class="card-body ">
                
                <div class="register-form">
                  <form [formGroup]="form" (ngSubmit)="onSubmit()">
                      
                   
                    <div class="form-group">
                      <label>اسم الشخص المعلق</label>
                      <input
                        type="text"
                        formControlName="name"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }"

                      />
                      <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">الاسم مطلوب</div>
                      </div>
                    </div>
                   
                    <div class="form-group">
                      <label>المهنة</label>
                      <input
                        type="text"
                        formControlName="job"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.job.errors }"

                      />
                      <div *ngIf="submitted && f.job.errors" class="invalid-feedback">
                        <div *ngIf="f.job.errors.required">المهنة مطلوب</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label> : التعليق</label>
                      <textarea 
                      type="text"
                      formControlName="comment"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.comment.errors }"
                      cols="20" rows="5">
                    </textarea>
                   
                      <div *ngIf="submitted && f.comment.errors" class="invalid-feedback">
                        <div *ngIf="f.comment.errors.required">required</div>
                      </div>
                    </div>
                   
                    <div class="form-group">
                      <label>: الصوره الشخصيه للمعلق   </label>
                      <img [src]="imgpath" width="100">
                      <input id="upload" type="file"   class="form-control border-0" #file type="file" 
                      (change)="uploadFile(file.files)"  required 
                      formControlName="imagePath"
                  /> 
                      <div *ngIf="submitted && f.imagePath.errors" class="invalid-feedback">
                        <div *ngIf="f.imagePath.errors.required">Image is Required</div>
                      </div>
                    </div>
                  
                  
            
                    <div class="form-group">
                      <button type="submit" class="btn btn-primary mr-5">Add</button>
                  
                    </div>
                  </form>
                  </div>
            </div>
      </div>
  </div>





</div>
