import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { DoctorService } from 'src/app/ServicesAPis/Doctor/doctor.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';
import { GalleryService } from 'src/app/ServicesAPis/Gallery/gallery.service';

@Component({
  selector: 'app-all-doctors',
  templateUrl: './all-doctors.component.html',
  styleUrls: ['./all-doctors.component.css']
})
export class AllDoctorsComponent implements OnInit {
  AddClicked: Boolean = false;
  index:number;
  isLoggedIn = false;
  showside = false;
  username?: string;
  doctorList:any[]=[];
  speciesName: string = "";


  constructor(
    private tokenStorageService: TokenStorageService ,  
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,     
    private router: Router,
    private doctorService: DoctorService,
    private  imageService:UploadImageService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;
     
    }
    else{
      this.router.navigate(['/login']);

    }
  
    this.retrieveAllDoctors();

  }
  retrieveAllDoctors(): void {
    
    this.doctorService.getAllDoctors()
    .subscribe(
      response => {
        this.doctorList = response;
      },
      error => {
        //console.log(error);
      });
  }
  retrieveAllDoctorsAsc(): void {
    
    this.doctorService.getAllDoctorsASC()
    .subscribe(
      response => {
        this.doctorList = response;
      },
      error => {
        //console.log(error);
      });
  }
  retrieveAllDoctorsAscNum(): void {
    
    this.doctorService.getAllDoctorsASCNum()
    .subscribe(
      response => {
        this.doctorList = response;
      },
      error => {
        //console.log(error);
      });
  }
  retrieveAllDoctorsNum(): void {
    
    this.doctorService.getAllDoctorsNum()
    .subscribe(
      response => {
        this.doctorList = response;
      },
      error => {
        //console.log(error);
      });
  }
  deletedoctor(id) {
    this.doctorService.deleteDoctor(id).subscribe((data:any)=>{
    this.retrieveAllDoctors();

  });
  }
  getSearchResult() {

    this.doctorService.getSearchResultDoctors(this.speciesName).subscribe(response => {
      this.doctorList = response;
    
    });
  }
}
