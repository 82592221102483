<div class="row mt-5" style="justify-content: center; " *ngIf="showside">
    <div class="col-xl-8 ">
        <div class="card easion-card" *ngIf="!isAddMode">
          <div class="card-header">
            <div class="easion-card-icon">
                <i class="fas fa-chart-bar"></i>
            </div>
            <div class="easion-card-title">Edit</div>
        

        </div>
            <div class="card-body ">
                
                <div class="register-form">
                  <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                      <label>User Name</label>
                      <input
                        type="text"
                        formControlName="userName"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.userName.errors }"

                      />
                      <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                        <div *ngIf="f.userName.errors.required">User Name is Required</div>
                      </div>
                    </div>
                    <div class="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          formControlName="email"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
  
                        />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Email is Required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <input
                          type="password"
                          formControlName="password"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
  
                        />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                          <div *ngIf="f.password.errors.required">Password is Required</div>
                        </div>
                      </div>
                    
                   
                  
                    <div class="form-group">
                      <button type="submit" class="btn btn-primary mr-5">update</button>
                      
                    </div>
                  </form>
                  </div>
            </div>
        </div>
    
        <div class="card easion-card" *ngIf="isAddMode">
            <div class="card-header">
              <div class="easion-card-icon">
                  <i class="fas fa-chart-bar"></i>
              </div>
              <div class="easion-card-title">Add Admin</div>
          
  
          </div>
              <div class="card-body ">
                  
                  <div class="register-form">
                      <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        
                        <div class="form-group">
                            <label>User Name</label>
                            <input
                              type="text"
                              formControlName="userName"
                              class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f.userName.errors }"
      
                            />
                            <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                              <div *ngIf="f.userName.errors.required">User Name is Required</div>
                            </div>
                          </div>
                          <div class="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                formControlName="email"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
        
                              />
                              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is Required</div>
                              </div>
                          </div>
                          <div class="form-group">
                              <label>Password</label>
                              <input
                                type="password"
                                formControlName="password"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
        
                              />
                              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is Required</div>
                              </div>
                            </div>
                          
            
                        <div class="form-group">
                          <button type="submit" class="btn btn-primary mr-5">add</button>
                          
                        </div>
                      </form>
                    </div>
              </div>
        </div>
    </div>

  

  
 
</div>
