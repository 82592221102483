import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Counter_Model } from '../Models/Counter_Model';
import { TokenStorageService } from '../ServicesAPis/Authorize/token-storage.service';
import { DoctorService } from '../ServicesAPis/Doctor/doctor.service';
import { CounterService } from '../ServicesAPis/Home_Counter/counter.service';

@Component({
  selector: 'app-counter-home-page',
  templateUrl: './counter-home-page.component.html',
  styleUrls: ['./counter-home-page.component.css']
})
export class CounterHomePageComponent implements OnInit {
  AddClicked: Boolean = false;
  index:number;
  isLoggedIn = false;
  showside = false;
  counterList:any[]=[];
  countSection:any;
   branchClicked: Boolean = false;
   doctorClicked: Boolean = false;
   yearClicked: Boolean = false;
   patientClicked: Boolean = false;
   form :FormGroup;

  constructor(
    private tokenStorageService: TokenStorageService ,  
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,     
    private router: Router,
    private counterService: CounterService,
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;
     
    }
    else{
      this.router.navigate(['/login']);

    }
    this.form = this.formBuilder.group(
      {
        
       
        doctorsCount: [Validators.required],
        happypatientsCount: [Validators.required],
        branchesCount: [Validators.required],
        expYearsCount: [Validators.required],
        


      }
    );
    this.retrieveAllNum();
  }
  retrieveAllNum(): void {
    
    this.counterService.getAllCounters()
    .subscribe(
      response => {
        this.countSection = response;
       //console.log(this.countSection)
      },
      error => {
        //console.log(error);
      });
  }
  editBranches():void{ 
  
      this.form.patchValue(this.countSection);  
  
    this.branchClicked = true;
    this.doctorClicked = false;
    this.yearClicked = false;
    this.patientClicked = false;


  }
  onSubmit(): void {
     
    //console.log(this.form.value)
   
    this.counterService.updateCounters(this.countSection.homeCounterID,this.form.value).subscribe((data:any)=>{
      this.router.navigate(['/counter']).then(() => {
        window.location.reload();
      });
      
  
     
    });
  
 
}
}
