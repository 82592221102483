<div class="row mt-5" style="justify-content: center;" *ngIf="showside">
   
<div class="container-xl">
    <div class="table-responsive">
        <div class="table-wrapper">
            <div class="table-title">
                <div class="row">
                    <div class="col-sm-3" style="text-align: initial;"><h2>Q & A <b>Details</b></h2></div>
                    <div class="col-sm-5">
                        <div class="search-box" style="width:inherit">
                            
                            <input  type="text" class="form-control"
                             placeholder="Search with type, question, answer"
                             id="speciesNameSearch"
                              name="speciesNameSearch" 
                            (keyup.enter)="getSearchResult()"
                             [(ngModel)]="speciesName">
                            <a class="btn primary">
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <a class="btn btn-info add-new " href="/Q&A-Editions" style="margin-left: auto;color: #fff;"> 
                            <i class="fa fa-plus"></i>                
                              Add Q & A
            
                        </a>
                    </div>
                </div>
            </div>
            <select [(ngModel)]="selectedLevel" (change)="selected()">
                <option *ngFor="let item of uniqueList" [ngValue]="item">{{item}}</option>
            </select>
            <table class="table table-striped table-hover table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Questions </th>
                        <th>Answers </th>
                        <th>Type </th>

                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let qa of qaList let i= index">
                        <td>{{i+1}}</td>
                        <td>{{qa.question}}</td>
                        <td>{{qa.answer}}</td>
                        <td>{{qa.type}}</td>

                        <td>
                          
                            <div style="display: inline-flex;">
                            <a [routerLink]="['/Q&A-Editions', qa.commonQuestionID]"  class="btn edit" title="Edit" data-toggle="tooltip"><i class="material-icons">&#xE254;</i></a>
                            <a (click)="deleteQA(qa.commonQuestionID)" class="btn delete" title="Delete" data-toggle="tooltip"><i class="material-icons">&#xE872;</i></a>
                            </div>
                        </td>
                    </tr>
                   
                   
                            
                </tbody>
            </table>
       
        </div>
    </div>  
</div>

</div>
