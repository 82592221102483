<div class="row mt-5" style="justify-content: center; text-align: right;"  *ngIf="showside">
    <div class="col-lg-11">
        <div class="container emp-profile" *ngIf="!isAddMode" dir="rtl">
                <form method="post">
                    <div class="row">
                   
                        <div class="col-md-6">
                            <div class="profile-head">
                                        <h5 *ngIf="formsection.lastService">
                                        تم اختيار المتابعه لنفس الخدمه السابقه
                                        </h5>
                                        <h5 *ngIf="!formsection.lastService">
                                            تم اختيار خدمة جديده في المتابعه
                                            </h5>
                                        
                                <ul class="nav nav-tabs mt-3" id="myTab" role="tablist" >
                                    <li class="nav-item">
                                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">بيانات المتابعة </a>
                                    </li>
                                   
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                    <div class="row">
                        
                        <div class="col-md-8">
                            <div class="tab-content profile-tab" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>رقم الهاتف</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p>                
                                                          {{formsection.patient.phoneNumber}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="!formsection.lastService">
                                                <div class="col-md-6">
                                                    <label>الخدمه المطلوبه</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p>{{formsection.serviceName}}</p>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="!formsection.lastService">
                                                <div class="col-md-6">
                                                    <label>الفرع</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p>{{formsection.branchName}}</p>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>التاريخ</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p>{{formsection.reservationDate}}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>الوقت</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p>{{formsection.patient.secondKnowUsFrom}}</p>
                                                </div>
                                            </div>
                                            
                                           
                                            
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </form>           
           
                 
        </div>
    <div class="container emp-profile" *ngIf="isAddMode">
        <div class="col-sm-8">
            <div class="search-box" style="width:inherit">
                
                <input  type="text" class="form-control"
                 placeholder="Search with  Service Name, Phone Number, Patient Name "
                 id="speciesNameSearch"
                  name="speciesNameSearch" 
                (keyup.enter)="getSearchResult()"
                 [(ngModel)]="speciesName">
                <a class="btn primary">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </a>
            </div>
        </div>
        <table class="table table-striped table-hover table-bordered" style="text-align: left;">
            <thead>
                <tr>
                    <th>#</th>
                    
                    <th>Phone </th>



                   
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of formList let i= index">
                    <td>{{i+1}}</td>
                    <td>{{item.patient.phoneNumber}}</td>

                   
                    <td>
                      
                        <div style="display: inline-flex;">

                        <a [routerLink]="['/Old-Patient', item.appointmentID]"  class="btn view" title="view" data-toggle="tooltip"><i class="material-icons">&#xE417;</i></a>
                        <a (click)="deleteitem(item.appointmentID)" class="btn delete" title="Delete" data-toggle="tooltip"><i class="material-icons">&#xE872;</i></a>
                        </div>
                    </td>
                </tr>
               
               
                        
            </tbody>
        </table>
             
    </div>
    </div>
</div>