import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../ServicesAPis/Authorize/token-storage.service';

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.css']
})
export class DashboardHomeComponent implements OnInit  {

  private roles: string[] = [];
  isLoggedIn = false;
  showside = false;
  username?: string;
  constructor(private tokenStorageService: TokenStorageService ,     private router: Router,
    ) { }
  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      // const user = this.tokenStorageService.getUser();
      // this.roles = user.roles;

      this.showside=true;
      // this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      // this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');

      // this.username = user.username;
    }
    else{
      this.router.navigate(['/login']);

    }
  }
  logout(): void {
    
    this.tokenStorageService.signOut();

  }
}
