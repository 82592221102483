import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


const baseUrl=environment.baseUrl+'admin/v1/service';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private http: HttpClient) { }

  getAllServices(): Observable<any> {
    return this.http.get<any>(baseUrl+"?sortbyasc=true");
  }
  getResult(query:any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}admin/v1/service?search=${query}&sortbyasc=true`);
  }
  getServiceByID(id:any): Observable<any> {
    return this.http.get(`${baseUrl}/${id}`);
  }
  updateServices(id:any,services:any): Observable<any> {
    return this.http.put(`${baseUrl}/${id}`,services);
  }
  
}
