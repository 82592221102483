<div *ngIf="showside">
<div class="row mt-5" style="justify-content: center; text-align: right;">
    <div class="col-xl-8 ">
    
      <div class="card easion-card" style=" width: 461px;margin: auto;" *ngIf="AddClicked">
        <div class="card-header">
            <div class="easion-card-icon">
                <i class="fas fa-chart-bar"></i>
            </div>
            <div class="easion-card-title"> Add Link</div>
        </div>
        <div class="card-body ">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
                      
            <div class="form-group">
              <label> : رابط  الفيديو  </label>
              <input
                type="text"
                formControlName="videoPath"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.videoPath.errors }"

              />
              <div *ngIf="submitted && f.videoPath.errors" class="invalid-feedback">
                <div *ngIf="f.videoPath.errors.required">اللينك مطلوب</div>
              </div>
            </div>

          
    
            <div class="form-group">
              <button type="submit" class="btn btn-primary mr-5">add</button>
          
            </div>
          </form>
        </div>
    </div>
    
        <div class="card easion-card">
          <div class="card-header">
              <div class="easion-card-icon">
                  <i class="fas fa-table"></i>
              </div>
              <div class="easion-card-title">روابط الفيديوهات</div>
            
            <a (click)="AddViewForm()" class="btn btn-info add-new " style="margin-left: auto;color: #fff;"> 
                <i class="fa fa-plus"></i>                  Add Link

            </a>
          </div>
          <div class="card-body ">
              <table class="table table-hover table-in-card" style="text-align: initial;">
                  <thead>
                      <tr>
                          <th scope="col">#</th>
                          <th class="link" scope="col">
                              Link
                              <a class="btn mr-0 pr-0" (click)="retrieveAllVedios()">                  
                                 <i  class="fa fa-arrow-down" aria-hidden="true"></i>
                              </a>
                              <a class="btn ml-0 pl-0" (click)="retrieveAllVediosASc()">                  

                                  <i class="fa fa-arrow-up" aria-hidden="true"></i>
                            </a>
                          </th>
                          <th scope="col"></th>
                      </tr>
                  </thead>
                  <tbody >
                      <tr *ngFor="let path of vedioList let i= index">
                          <th scope="row">{{i+1}}</th>
                          <td><a [attr.href]="path.videoPath" target="_blank" style="color:lightseagreen">
                              <i class="fa fa-play-circle mr-2" aria-hidden="true"></i> Wach now </a></td>
                          <td>                                       
                         <a class="btn" (click)="deleteVedio(path.happyPatientID)"> <i class="fas fa-trash-alt" style="color:red"></i></a>

                          </td>
                      </tr>
                     
                    
                  </tbody>
              </table>
          </div>
      </div>
    </div>


  
 
</div>

<div class="row mt-5" style="justify-content: center; text-align: right;">
  <div class="col-xl-11 ">

    

<div class="container-xl">
    <div class="table-responsive">
        <div class="table-wrapper">
            <div class="table-title">
                <div class="row">
                    <div class="col-sm-3" style="text-align: initial;"><h2>Reviews <b>Details</b></h2></div>
                    <div class="col-sm-5">
                        <div class="search-box" style="width:inherit">
                            
                            <input  type="text" class="form-control"
                             placeholder="Search with name or job or comment"
                             id="speciesNameSearch"
                              name="speciesNameSearch" 
                            (keyup.enter)="getSearchResult()"
                             [(ngModel)]="speciesName">
                            <a class="btn primary">
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <a class="btn btn-info add-new " href="/Patient-Review-Editions" style="margin-left: auto;color: #fff;"> 
                            <i class="fa fa-plus"></i>                
                              Add Review
            
                        </a>
                    </div>
                </div>
            </div>
            <table class="table table-striped table-hover table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th class="link">
                            Photo   
                            <a (click)="retrieveAllReviewAsc()" class="btn"> <i class="fa fa-arrow-up" aria-hidden="true"></i></a>
                            <a (click)="retrieveAllReviews()" class="btn"> <i class="fa fa-arrow-down" aria-hidden="true"></i></a>
                        </th>
                        <th>Name </th>
                        <th>Job </th>
                        <th>Comment</th>
                        <th class="link" style="text-align: initial;">
                            Star 
                            <a (click)="retrieveAllReviewsIsFav()" class="btn" > <i class="fa fa-arrow-up" aria-hidden="true"></i></a>
                            <a (click)="retrieveAllReviewIsFavAsc()" class="btn"> <i class="fa fa-arrow-down" aria-hidden="true"></i></a>
                       
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let review of reviewList let i= index">
                        <td>{{i+1}}</td>
                        <td><img [src]="review.imagePath" width="100"></td>
                        <td>{{review.name}}</td>
                        <td>{{review.job}}</td>
                        <td>{{review.comment}}</td>
                        <td>
                            <i *ngIf="review.isFavorite" class="fa fa-star" aria-hidden="true" style="color: yellow;"></i>
                        </td>
                        <td>
                            <a (click)="addFavorit(review.happyPatientID ,false , review)" class="btn delete" title="Delete Favorit" data-toggle="tooltip">
                                <i class="fa fa-minus" aria-hidden="true"></i>
                            </a>
                            <a (click)="addFavorit(review.happyPatientID ,true , review)" class="btn edit" title="Add favorit" data-toggle="tooltip">
                                <i class="fa fa-plus" aria-hidden="true"></i>
                            </a>
                            <br>
                            <br>
                            <div style="display: inline-flex;">
                            <a [routerLink]="['/Patient-Review-Editions', review.happyPatientID]"  class="btn edit" title="Edit" data-toggle="tooltip"><i class="material-icons">&#xE254;</i></a>
                            <a (click)="deleteReview(review.happyPatientID)" class="btn delete" title="Delete" data-toggle="tooltip"><i class="material-icons">&#xE872;</i></a>
                            </div>
                        </td>
                    </tr>
                   
                   
                            
                </tbody>
            </table>
       
        </div>
    </div>  
</div>

</div>
</div>

</div>
