import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Services_Model } from 'src/app/Models/Services_Model';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';
import { ServicesService } from 'src/app/ServicesAPis/Services/services.service';

@Component({
  selector: 'app-services-edition',
  templateUrl: './services-edition.component.html',
  styleUrls: ['./services-edition.component.css']
})
export class ServicesEditionComponent implements OnInit {
  isAddMode: boolean;
  id:string;
  form :FormGroup;
  submitted = false;
  isLoggedIn = false;
  showside = false;
  imagesList:any[]=[];
  imgsection:any
  serviceListData:Services_Model[]=[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService ,
    private serviceService : ServicesService,
    private  imageService:UploadImageService
  ) {}


  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
    this.form = this.formBuilder.group(
      {
        name: ['', Validators.required],
        info: ['', Validators.required],
        videoPath: [''],
        infoPartOne: [''],
        infoPartTwo: [''],  // imagePath

      
        
      }
    );
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    if (!this.isAddMode) {
      this.form.get('infoPartTwo').setValue(File, {emitModelToViewChange: false});

        this.serviceService.getServiceByID(this.id)
            .subscribe(x => {
              this.imgsection=x.infoPartTwo;
              this.form.patchValue(x);  
          });
      
  }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit():void{
    //console.log(this.form.value)
    this.serviceService.updateServices(this.id,this.form.value).subscribe((data:any)=>{
      this.router.navigate(['/Services']);
     
    });
  }
  uploadFile= (files) => {
    this.imageService.uploadFile(files).subscribe((data:any)=>{
      this.form.value.infoPartTwo=data.path

        


    });
    }
}