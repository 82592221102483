<div class="row mt-5" style="justify-content: center; text-align: right;">
  <div class="row">
   
  </div>
    <div class="col-xl-8 ">
        <div class="card easion-card" *ngIf="!isAddMode">
          <div class="card-header">
            <div class="easion-card-icon">
                <i class="fas fa-chart-bar"></i>
            </div>
            <div class="easion-card-title">Edit</div>
        

        </div>
            <div class="card-body ">
                
              
                <!-- <div class="register-form">
                  <form [formGroup]="blogForm" (ngSubmit)="onSubmit()">
                        
                    <div class="form-group">
                        <label>Title</label>
                        <input
                          type="text"
                          formControlName="title"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.title.errors }"

                        />
                        <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                          <div *ngIf="f.title.errors.required">العنوان مطلوب</div>
                        </div>
                    </div>
                    <div class="form-group">
                      <label>Writer</label>
                      <input
                        type="text"
                        formControlName="writter"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.writter.errors }"

                      />
                      <div *ngIf="submitted && f.writter.errors" class="invalid-feedback">
                        <div *ngIf="f.writter.errors.required">الكاتب مطلوب</div>
                      </div>
                   </div>
                 
                    <div class="form-group">
                      <label> Cover Image</label>
                      <input id="upload" type="file"   class="form-control border-0" #file1 type="file" 
                      (change)="uploadFile1(file1.files)"  required 
                      formControlName="imagePath"
                      />      
                      <div *ngIf="submitted && f.imagePath.errors" class="invalid-feedback">
                        <div *ngIf="f.imagePath.errors.required">Image is Required</div>
                      </div>           
                   
                    </div>

                    <div class="form-group">
                      <label for=""> Doctor Name That Write this topic</label>
                      <select class="custom-select" (change)="changeDoctor($event)" formControlName="doctorID">
                        <option value="" disabled>Choose your the doctor</option>
                        <option *ngFor="let doctor of doctorList" [ngValue]="doctor.id">{{doctor.name}}</option>
                     </select>
                     <div *ngIf="isSubmitted && doctorID.errors?.required">
                      <sup>*</sup>Please enter choose doctor name
                   </div>
                    </div>

                    <div class="form-group"    
                    >
                      <div
                      formArrayName="blogDetails"
                      *ngFor="let section of blogForm.get('blogDetails')['controls']; let i = index;"
                      
                    >
                    <div [formGroupName]="i" >
                      <input formControlName="title" placeholder="section title" [value]="section.title">
                      <input formControlName="discreption" placeholder="section discreption">
                      <input #myInput id="upload" type="file"   class="form-control border-0" #file2 type="file" 
                      (change)="uploadFile2(file2.files , i)"  required 
                      formControlName="imagePath" placeholder="upload image section "
                      /> 

                    </div>
                  </div>
                    <button type="button" (click)="addSection()">Add section</button>
                    </div>
                    <div class="form-group">
                      <button type="submit" class="btn btn-primary mr-5">update</button>
                      
                    </div>
                  </form>
                </div> -->


            </div>
        </div>
    
        <div class="card easion-card" *ngIf="isAddMode">
            <div class="card-header">
              <div class="easion-card-icon">
                  <i class="fas fa-chart-bar"></i>
              </div>
              <div class="easion-card-title">Add</div>
          
  
          </div>
              <div class="card-body ">
                  
                  <div class="register-form">
                      <form [formGroup]="blogForm" (ngSubmit)="onSubmit()">
                        
                        <div class="form-group">
                            <label>Title</label>
                            <input
                              type="text"
                              formControlName="title"
                              class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
    
                            />
                            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                              <div *ngIf="f.title.errors.required">العنوان مطلوب</div>
                            </div>
                        </div>
                        <div class="form-group">
                          <label>Writer</label>
                          <input
                            type="text"
                            formControlName="writter"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.writter.errors }"
  
                          />
                          <div *ngIf="submitted && f.writter.errors" class="invalid-feedback">
                            <div *ngIf="f.writter.errors.required">الكاتب مطلوب</div>
                          </div>
                       </div>
                     
                        <div class="form-group">
                          <label> Cover Image</label>
                          <input id="upload" type="file"   class="form-control border-0" #file1 type="file" 
                          (change)="uploadFile1(file1.files)"  required 
                          formControlName="imagePath"
                          />      
                          <div *ngIf="submitted && f.imagePath.errors" class="invalid-feedback">
                            <div *ngIf="f.imagePath.errors.required">Image is Required</div>
                          </div>           
                         <!-- <p class="mt-3">
                            <img src="../../../assets/img/download (5).png" alt="Dr.Mohamed" width="100">
                         </p> -->
                        </div>

                        <div class="form-group">
                          <label for=""> Doctor Name That Write this topic</label>
                          <select class="custom-select" (change)="changeDoctor($event)" formControlName="doctorID">
                            <option value="" disabled>Choose your the doctor</option>
                            <option *ngFor="let doctor of doctorList" [ngValue]="doctor.id">{{doctor.name}}</option>
                         </select>
                         <div *ngIf="isSubmitted && doctorID.errors?.required">
                          <sup>*</sup>Please enter choose doctor name
                       </div>
                        </div>

                        <div class="form-group">
                          <h3>Sections of Blog </h3>
                          <div
                          formArrayName="blogDetails"
                          *ngFor="let section of blogForm.get('blogDetails')['controls']; let i = index;"
                        >
                        <div [formGroupName]="i">
                          <div class="form-group">
                            <label>Title</label>
                            <input formControlName="title" placeholder="section title"                             class="form-control"
                            >

                           
                        </div>
                        <div class="form-group">
                          <label>Discreption</label>
                          <textarea formControlName="discreption" placeholder="section discreption" cols="50" rows="10"                            class="form-control"
                          ></textarea>

                         
                      </div>
                      <div class="form-group">
                        <label>Image</label>
                        <input #myInput id="upload" type="file"   class="form-control border-0" #file2 type="file" 
                        (change)="uploadFile2(file2.files , i)"  required 
                        formControlName="imagePath" placeholder="upload image section "
                        /> 
                       
                    </div>
                    <input type="hidden" formControlName="detailsOrder" [ngModel]="i + 1">

                        </div>
                      </div>
                        <button type="button" class="btn btn-danger" (click)="addSection()">Add section</button>
                        </div>
                        <div class="form-group">
                          <button type="submit" class="btn btn-primary mr-5">add Blog</button>
                          
                        </div>
                      </form>
                    </div>
              </div>
        </div>
    </div>

  

  
 
</div>
