<div class="row mt-5" style="justify-content: center; " *ngIf="showside">
    <div class="col-xl-8 ">
        <div class="card easion-card" *ngIf="!isAddMode">
          <div class="card-header">
            <div class="easion-card-icon">
                <i class="fas fa-chart-bar"></i>
            </div>
            <div class="easion-card-title">Edit</div>
        
  
        </div>
            <div class="card-body ">
                
                <div class="register-form">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                          
                        <div class="form-group">
                          <label>اسم الطبيب</label>
                          <input
                            type="text"
                            formControlName="name"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
      
                          />
                          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">الاسم مطلوب</div>
                          </div>
                        </div>
                       
                        <div class="form-group">
                          <label> : مايعرض في الصفحة الرئيسية وصفحه الأطباء</label>
                          <textarea 
                          type="text"
                          formControlName="firstDiscription"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.firstDiscription.errors }"
                          cols="20" rows="5">
                        </textarea>
                       
                          <div *ngIf="submitted && f.firstDiscription.errors" class="invalid-feedback">
                            <div *ngIf="f.firstDiscription.errors.required">لايمكن كتابه اكتر من 300 حرف</div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>:  الفقرات التعريفية للطبيب لصفحته الخاصه</label>
                          <textarea 
                          type="text"
                          formControlName="secondDiscription"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.secondDiscription.errors }"
                          cols="20" rows="5">
                        </textarea>
                        <div *ngIf="submitted && f.secondDiscription.errors" class="invalid-feedback">
                          <div *ngIf="f.secondDiscription.errors.required">لايمكنكتابه اكتر من 300 حرف</div>
                        </div>
                        <textarea 
                        type="text"
                        formControlName="thirdDiscription"
                        class="form-control mt-3"
                        [ngClass]="{ 'is-invalid': submitted && f.thirdDiscription.errors }"
                        cols="20" rows="5">
                      </textarea>
                       
                          
                          <div *ngIf="submitted && f.thirdDiscription.errors" class="invalid-feedback">
                            <div *ngIf="f.thirdDiscription.errors.required">لايمكنكتابه اكتر من 288 حرف</div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>الرقم النقابي </label>
                          <input
                            type="text"
                            formControlName="referalNumber"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.referalNumber.errors }"
      
                          />
                          <div *ngIf="submitted && f.referalNumber.errors" class="invalid-feedback">
                            <div *ngIf="f.referalNumber.errors.required">الرقم النقابي مطلوب</div>
                          </div>
                        </div>
      
                        <div class="form-group">
                            <label> : الصوره الشخصيه للطبيب المعروضه في الصفحه الرئيسية والصفحة الشخصية</label>
                            <input id="upload" type="file"   class="form-control border-0" #file1 type="file" 
                            (change)="uploadFile1(file1.files)"  required 
                            formControlName="homeImagePath"
                            />      
                            <div *ngIf="submitted && f.homeImagePath.errors" class="invalid-feedback">
                              <div *ngIf="f.homeImagePath.errors.required">Image is Required</div>
                            </div>           
                           <!-- <p class="mt-3">
                              <img src="../../../assets/img/download (5).png" alt="Dr.Mohamed" width="100">
                           </p> -->
                          </div>
                          <div class="form-group">
                            <label> : الصوره الشخصيه للطبيب في الصفحه المجمعة للاطباء</label>
                            <input id="upload" type="file"   class="form-control border-0" #file2 type="file" 
                            (change)="uploadFile2(file2.files)"  required 
                            formControlName="personalImagePath"
                            />      
                            <div *ngIf="submitted && f.personalImagePath.errors" class="invalid-feedback">
                              <div *ngIf="f.personalImagePath.errors.required">Image is Required</div>
                            </div> 
                          </div>
                          <div class="form-group">
                            <label> : الصوره الشخصيه للطبيب في القسم المشترك لكل الصفحات  </label>
                            <input id="upload" type="file"   class="form-control border-0" #file3 type="file" 
                            (change)="uploadFile3(file3.files)"  required 
                            formControlName="exploreImagePath"
                            />      
                            <div *ngIf="submitted && f.exploreImagePath.errors" class="invalid-feedback">
                              <div *ngIf="f.exploreImagePath.errors.required">Image is Required</div>
                            </div> 
                          </div>
                        <div class="form-group">
                          <label> : لينك الفيديو التعريفي الخاص بالطبيب</label>
                          <input
                            type="text"
                            formControlName="videoPath"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.videoPath.errors }"
      
                          />
                          <div *ngIf="submitted && f.videoPath.errors" class="invalid-feedback">
                            <div *ngIf="f.videoPath.errors.required">اللينك مطلوب</div>
                          </div>
                        </div>
                        
                        <div class="form-group">
                          <label>لينك للملف الشخصي في  التيكتوك</label>
                          <input
                            type="text"
                            formControlName="tiktokURL"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.tiktokURL.errors }"
      
                          />
                          <div *ngIf="submitted && f.tiktokURL.errors" class="invalid-feedback">
                            <div *ngIf="f.tiktokURL.errors.required">اللينك مطلوب</div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>لينك للملف الشخصي في  الفيسبوك</label>
                          <input
                            type="text"
                            formControlName="facebookURL"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.facebookURL.errors }"
      
                          />
                          <div *ngIf="submitted && f.facebookURL.errors" class="invalid-feedback">
                            <div *ngIf="f.facebookURL.errors.required">اللينك مطلوب</div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>لينك للملف الشخصي في  الانستجرام</label>
                          <input
                            type="text"
                            formControlName="instgramURL"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.instgramURL.errors }"
      
                          />
                          <div *ngIf="submitted && f.instgramURL.errors" class="invalid-feedback">
                            <div *ngIf="f.instgramURL.errors.required">اللينك مطلوب</div>
                          </div>
                        </div>
                        
                        <div class="form-group">
                          <label>لينك للملف الشخصي في  لينكد ان</label>
                          <input
                            type="text"
                            formControlName="linkedInURL"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.linkedInURL.errors }"
      
                          />
                          <div *ngIf="submitted && f.linkedInURL.errors" class="invalid-feedback">
                            <div *ngIf="f.linkedInURL.errors.required">اللينك مطلوب</div>
                          </div>
                        </div>
                     
                        <!-- <div class="form-group">
                          <label>اضافه لقائمه الصفحه الرئيسية ؟</label>
                          <select
                          formControlName="homeDisplay"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.homeDisplay.errors }">
                            <option value="true">نعم</option>
                            <option value="false">لا</option>
        
                          </select>
                        
                          <div *ngIf="submitted && f.homeDisplay.errors" class="invalid-feedback">
                            <div *ngIf="f.homeDisplay.errors.required"> مطلوب</div>
                          </div>
                        </div>
                       -->
                        <div class="form-group">
                          <button type="submit" class="btn btn-primary mr-5">Update</button>
                          <!-- <button
                            type="button"
                            (click)="onReset()"
                            class="btn btn-warning float-right"
                          >
                            Reset
                          </button> -->
                        </div>
                        
                      </form>
                  </div>
            </div>
        </div>
    
        <div class="card easion-card" *ngIf="isAddMode">
            <div class="card-header">
              <div class="easion-card-icon">
                  <i class="fas fa-chart-bar"></i>
              </div>
              <div class="easion-card-title">Add Doctor</div>
          
  
          </div>
              <div class="card-body ">
                <div class="register-form">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                          
                      <div class="form-group">
                        <label>اسم الطبيب</label>
                        <input
                          type="text"
                          formControlName="name"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
    
                        />
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                          <div *ngIf="f.name.errors.required">الاسم مطلوب</div>
                        </div>
                      </div>
                     
                      <div class="form-group">
                        <label> : مايعرض في الصفحة الرئيسية وصفحه الأطباء</label>
                        <textarea 
                        type="text"
                        formControlName="firstDiscription"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.firstDiscription.errors }"
                        cols="20" rows="5">
                      </textarea>
                     
                        <div *ngIf="submitted && f.firstDiscription.errors" class="invalid-feedback">
                          <div *ngIf="f.firstDiscription.errors.required">لايمكن كتابه اكتر من 300 حرف</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>:  الفقرات التعريفية للطبيب لصفحته الخاصه</label>
                        <textarea 
                        type="text"
                        formControlName="secondDiscription"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.secondDiscription.errors }"
                        cols="20" rows="5">
                      </textarea>
                      <div *ngIf="submitted && f.secondDiscription.errors" class="invalid-feedback">
                        <div *ngIf="f.secondDiscription.errors.required">لايمكنكتابه اكتر من 300 حرف</div>
                      </div>
                      <textarea 
                      type="text"
                      formControlName="thirdDiscription"
                      class="form-control mt-3"
                      [ngClass]="{ 'is-invalid': submitted && f.thirdDiscription.errors }"
                      cols="20" rows="5">
                    </textarea>
                     
                        
                        <div *ngIf="submitted && f.thirdDiscription.errors" class="invalid-feedback">
                          <div *ngIf="f.thirdDiscription.errors.required">لايمكنكتابه اكتر من 288 حرف</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>الرقم النقابي </label>
                        <input
                          type="text"
                          formControlName="referalNumber"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.referalNumber.errors }"
    
                        />
                        <div *ngIf="submitted && f.referalNumber.errors" class="invalid-feedback">
                          <div *ngIf="f.referalNumber.errors.required">الرقم النقابي مطلوب</div>
                        </div>
                      </div>
    
                      <div class="form-group">
                          <label> : الصوره الشخصيه للطبيب المعروضه في الصفحه الرئيسية والصفحة الشخصية</label>
                          <input id="upload" type="file"   class="form-control border-0" #file1 type="file" 
                          (change)="uploadFile1(file1.files)"  required 
                          formControlName="homeImagePath"
                          />      
                          <div *ngIf="submitted && f.homeImagePath.errors" class="invalid-feedback">
                            <div *ngIf="f.homeImagePath.errors.required">Image is Required</div>
                          </div>           
                         <!-- <p class="mt-3">
                            <img src="../../../assets/img/download (5).png" alt="Dr.Mohamed" width="100">
                         </p> -->
                        </div>
                        <div class="form-group">
                          <label> : الصوره الشخصيه للطبيب في الصفحه المجمعة للاطباء</label>
                          <input id="upload" type="file"   class="form-control border-0" #file2 type="file" 
                          (change)="uploadFile2(file2.files)"  required 
                          formControlName="personalImagePath"
                          />      
                          <div *ngIf="submitted && f.personalImagePath.errors" class="invalid-feedback">
                            <div *ngIf="f.personalImagePath.errors.required">Image is Required</div>
                          </div> 
                        </div>
                        <div class="form-group">
                          <label> : الصوره الشخصيه للطبيب في القسم المشترك لكل الصفحات  </label>
                          <input id="upload" type="file"   class="form-control border-0" #file3 type="file" 
                          (change)="uploadFile3(file3.files)"  required 
                          formControlName="exploreImagePath"
                          />      
                          <div *ngIf="submitted && f.exploreImagePath.errors" class="invalid-feedback">
                            <div *ngIf="f.exploreImagePath.errors.required">Image is Required</div>
                          </div> 
                        </div>
                      <div class="form-group">
                        <label> : لينك الفيديو التعريفي الخاص بالطبيب</label>
                        <input
                          type="text"
                          formControlName="videoPath"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.videoPath.errors }"
    
                        />
                        <div *ngIf="submitted && f.videoPath.errors" class="invalid-feedback">
                          <div *ngIf="f.videoPath.errors.required">اللينك مطلوب</div>
                        </div>
                      </div>
                      
                      <div class="form-group">
                        <label>لينك للملف الشخصي في  التيكتوك</label>
                        <input
                          type="text"
                          formControlName="tiktokURL"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.tiktokURL.errors }"
    
                        />
                        <div *ngIf="submitted && f.tiktokURL.errors" class="invalid-feedback">
                          <div *ngIf="f.tiktokURL.errors.required">اللينك مطلوب</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>لينك للملف الشخصي في  الفيسبوك</label>
                        <input
                          type="text"
                          formControlName="facebookURL"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.facebookURL.errors }"
    
                        />
                        <div *ngIf="submitted && f.facebookURL.errors" class="invalid-feedback">
                          <div *ngIf="f.facebookURL.errors.required">اللينك مطلوب</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>لينك للملف الشخصي في  الانستجرام</label>
                        <input
                          type="text"
                          formControlName="instgramURL"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.instgramURL.errors }"
    
                        />
                        <div *ngIf="submitted && f.instgramURL.errors" class="invalid-feedback">
                          <div *ngIf="f.instgramURL.errors.required">اللينك مطلوب</div>
                        </div>
                      </div>
                      
                      <div class="form-group">
                        <label>لينك للملف الشخصي في  لينكد ان</label>
                        <input
                          type="text"
                          formControlName="linkedInURL"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.linkedInURL.errors }"
    
                        />
                        <div *ngIf="submitted && f.linkedInURL.errors" class="invalid-feedback">
                          <div *ngIf="f.linkedInURL.errors.required">اللينك مطلوب</div>
                        </div>
                      </div>
                   
                      <!-- <div class="form-group">
                        <label>اضافه لقائمه الصفحه الرئيسية ؟</label>
                        <select
                        formControlName="homeDisplay"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.homeDisplay.errors }">
                          <option value="true">نعم</option>
                          <option value="false">لا</option>
      
                        </select>
                      
                        <div *ngIf="submitted && f.homeDisplay.errors" class="invalid-feedback">
                          <div *ngIf="f.homeDisplay.errors.required"> مطلوب</div>
                        </div>
                      </div> -->
                    
                      <div class="form-group">
                        <button type="submit" class="btn btn-primary mr-5">add</button>
                        <!-- <button
                          type="button"
                          (click)="onReset()"
                          class="btn btn-warning float-right"
                        >
                          Reset
                        </button> -->
                      </div>
                      
                    </form>
                    </div>
              </div>
        </div>
    </div>
  
  
  
  
  
  </div>
  