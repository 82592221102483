<div class="row mt-5" style="justify-content: center;" *ngIf="showside">

    <!-- Add Images-->
    <div class="col-xl-6" id="branch"  [className]="AddClicked ? 'show' : 'hide'">
        <div class="card easion-card" style=" width: 461px;margin: auto;">
            <div class="card-header">
                <div class="easion-card-icon">
                    <i class="fas fa-chart-bar"></i>
                </div>
                <div class="easion-card-title"> Add Images</div>
            </div>
            <div class="card-body ">
               
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        
                    <div class="form-group">
                        <label>Upload Images</label>
                        <input id="upload" type="file"   class="form-control border-0" #file type="file" 
                        (change)="uploadFile(file.files)"  required 
                        formControlName="imagePath"
                    /> 
                        <div *ngIf="submitted && f.imagePath.errors" class="invalid-feedback">
                          <div *ngIf="f.imagePath.errors.required">Image is Required</div>
                        </div>
                      </div>
                    
                      
        
                    <div class="form-group">
                      <button type="submit" class="btn btn-primary mr-5">add</button>
                      
                    </div>
                  </form>
            </div>
        </div>
       
    </div>
       <!-- End -->
</div>
<div class="row mt-5" style="justify-content: center;">
   
    <!-- When click to star add to home page and be red color and in another click deleted from the home page and be blacked-->
    <div class="col-xl-11">
        <div class="card easion-card " >
            <div class="card-header">
                <div class="easion-card-icon">
                    <i class="fas fa-chart-bar"></i>
                </div>
                <div class="easion-card-title">Images 
                   <a (click)="retrieveAllPhotosAsc()" class="btn"> <i class="fa fa-arrow-up" aria-hidden="true"></i></a>
                   <a (click)="retrieveAllPhotos()" class="btn"> <i class="fa fa-arrow-down" aria-hidden="true"></i></a>
                </div>
                <a (click)="AddViewForm()" class="btn btn-info add-new " style="margin-left: auto;color: #fff;"> 
                    <i class="fa fa-plus"></i>  Add Photos
                </a>

            </div>
            <div class="row" style="display: inline-flex;">
               <div class="col-lg-3 mr-2 mt-2 mb-2" style="display: -webkit-inline-box;" *ngFor="let image of imagesList let i= index">
            
                <div class="card" style="background-color: rgb(223, 221, 217);">
                    <a class="btn" *ngIf="image.isFavorite"> <i class="fas fa-heart" style="color:rgb(141, 5, 5)"></i></a>
                    
                    <img class="card-img-top" [src]="image.imagePath" alt="Card image cap">
                    <div class="card-body" style="padding: initial;">
                      <p class="card-text pt-3 pb-3">
                        <a class="btn" (click)="deletePhoto(image.galleryID)"> <i class="fas fa-trash-alt" style="color: red;"></i></a>
                        
                        <a class="btn add mr-2" (click)="addFavorit(image.galleryID ,true , image)"> <i class="fa fa-plus"></i>  Add star</a>
                        <a class="btn remove" (click)="addFavorit(image.galleryID , false , image)"> <i class="fa fa-minus"></i>  Remove star</a>

                     </p>
                    </div>
                   
                </div>
                   
                  </div> 
                   
            </div>
        </div>
              
              
              


     </div>
</div>