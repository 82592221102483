<div class="row mt-5" style="justify-content: center; text-align: right;" *ngIf="showside">
    <div class="col-xl-8 ">
        <div class="card easion-card" *ngIf="!isAddMode">
          <div class="card-header">
            <div class="easion-card-icon">
                <i class="fas fa-chart-bar"></i>
            </div>
            <div class="easion-card-title">Edit</div>
        

        </div>
            <div class="card-body ">
                
                <div class="register-form">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                      
                        
                      <div class="form-group">
                        <label> : السؤال</label>
                        <textarea 
                        type="text"
                        formControlName="question"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.question.errors }"
                        cols="20" rows="5">
                      </textarea>
                     
                        <div *ngIf="submitted && f.question.errors" class="invalid-feedback">
                          <div *ngIf="f.question.errors.required">النص مطلوب</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label> : الاجابة</label>
                        <textarea 
                        type="text"
                        formControlName="answer"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.answer.errors }"
                        cols="20" rows="5">
                      </textarea>
                     
                        <div *ngIf="submitted && f.answer.errors" class="invalid-feedback">
                          <div *ngIf="f.answer.errors.required">النص مطلوب</div>
                        </div>
                    </div>
                    <div class="form-group">
                      <label>نوع السؤال</label>
                      <input
                        type="text"
                        formControlName="type"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.type.errors }"

                      />
                      <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                        <div *ngIf="f.type.errors.required"> مطلوب</div>
                      </div>
                    </div>
                
                      <div class="form-group">
                        <button type="submit" class="btn btn-primary mr-5">Update</button>
                    
                      </div>
                      
                    </form>
                  </div>
            </div>
        </div>
    
        <div class="card easion-card" *ngIf="isAddMode">
            <div class="card-header">
              <div class="easion-card-icon">
                  <i class="fas fa-chart-bar"></i>
              </div>
              <div class="easion-card-title">Add</div>
          
  
          </div>
              <div class="card-body ">
                  
                  <div class="register-form">
                      <form [formGroup]="form" (ngSubmit)="onSubmit()">
                      
                        <div class="form-group">
                            <label> : السؤال</label>
                            <textarea 
                            type="text"
                            formControlName="question"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.question.errors }"
                            cols="20" rows="5">
                          </textarea>
                         
                            <div *ngIf="submitted && f.question.errors" class="invalid-feedback">
                              <div *ngIf="f.question.errors.required">النص مطلوب</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label> : الاجابة</label>
                            <textarea 
                            type="text"
                            formControlName="answer"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.answer.errors }"
                            cols="20" rows="5">
                          </textarea>
                         
                            <div *ngIf="submitted && f.answer.errors" class="invalid-feedback">
                              <div *ngIf="f.answer.errors.required">النص مطلوب</div>
                            </div>
                        </div>
                        <div class="form-group">
                          <label>نوع السؤال</label>
                          <input
                            type="text"
                            formControlName="type"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.type.errors }"
  
                          />
                          <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                            <div *ngIf="f.type.errors.required"> مطلوب</div>
                          </div>
                        </div>

                        <div class="form-group">
                          <button type="submit" class="btn btn-primary mr-5">add</button>
                          
                        </div>
                      </form>
                    </div>
              </div>
        </div>
    </div>

  

  
 
</div>
