<div class="row mt-5" style="justify-content: center; text-align: right;"  *ngIf="showside">
    <div class="col-lg-11">
        
    <div class="container emp-profile" >
        
        <table class="table table-striped table-hover table-bordered" style="text-align: left;">
            <thead>
                <tr>
                    <th>#</th>
                    
                    <th>Email </th>
                  


                   
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of formList let i= index">
                    <td>{{i+1}}</td>
                    <td>{{item.email}}</td>
                   

                   
                    <td>
                      
                        <div style="display: inline-flex;">

                        <a (click)="deleteitem(item.subscribtionID)" class="btn delete" title="Delete" data-toggle="tooltip"><i class="material-icons">&#xE872;</i></a>
                        </div>
                    </td>
                </tr>
               
               
                        
            </tbody>
        </table>
             
    </div>
    </div>
</div>