<div class="row mt-5" style="justify-content: center;" *ngIf="showside">
    <div class="container-xl">
        <div class="table-responsive">
            <div class="table-wrapper">
                <div class="table-title">
                    <div class="row">
                        <div class="col-sm-2" style="text-align: initial;"><h2>Doctor <b>Details</b></h2></div>
                        <div class="col-sm-5">
                            <div class="search-box" style="width:inherit">
                                
                                <input  type="text" class="form-control"
                                 placeholder="Search with Name, Referal Number"
                                 id="speciesNameSearch"
                                  name="speciesNameSearch" 
                                (keyup.enter)="getSearchResult()"
                                 [(ngModel)]="speciesName">
                                <a class="btn primary">
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <a class="btn btn-info add-new " href="/Doctor-Edition" style="margin-left: auto;color: #fff;"> 
                                <i class="fa fa-plus"></i>                
                                  Add Doctor
                
                            </a>
                            <a class="btn btn-info add-new mr-5  " href="/Patient-Editions" style="margin-left: auto;color: #fff;"> 
                                <i class="fa fa-plus"></i>                
                                  Add Patients
                
                            </a>
                        </div>
                        
                    </div>
                </div>
                <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th class="link">
                                Name   
                                <a (click)="retrieveAllDoctorsAsc()" class="btn"> <i class="fa fa-arrow-up" aria-hidden="true"></i></a>
                                <a (click)="retrieveAllDoctors()" class="btn"> <i class="fa fa-arrow-down" aria-hidden="true"></i></a>
                            </th>
                            <th class="link">
                                Referal Number
                                <!-- <a (click)="retrieveAllDoctorsAscNum()" class="btn"> <i class="fa fa-arrow-up" aria-hidden="true"></i></a>
                                <a (click)="retrieveAllDoctorsNum()" class="btn"> <i class="fa fa-arrow-down" aria-hidden="true"></i></a>
                             -->
                            </th>
                            
                            <!-- <th class="link" style="text-align: initial;">
                                Star 
                               
                            </th> -->
                            <th>Patients & Article</th>


                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let doctor of doctorList let i= index">
                            <td>{{i+1}}</td>
                            <td>{{doctor.name}}</td>
                            <td>{{doctor.referalNumber}}</td>
                            <td>
                                <a [routerLink]="['/Doctors-Patients', doctor.doctorID]" class="btn btn-primary" style="color: white;">
                                    His Patients
                                </a>
                                 <a [routerLink]="['/All-Blogs', doctor.doctorID]" class="btn btn-primary" style="color: white;">
                                    His Blogs
                                </a>
                            </td>
                            <!-- <td>
                                <i *ngIf="doctor.homeDisplay" class="fa fa-star" aria-hidden="true" style="color: yellow;"></i>
                            </td> -->
                            <td>
                               
                                <div style="display: inline-flex;">
                               <a  [routerLink]="['/Doctor-Details', doctor.doctorID]"class="btn view" title="View" data-toggle="tooltip"><i class="material-icons">&#xE417;</i></a>
                                <a [routerLink]="['/Doctor-Edition', doctor.doctorID]"  class="btn edit" title="Edit" data-toggle="tooltip"><i class="material-icons">&#xE254;</i></a>
                                <a (click)="deletedoctor(doctor.doctorID)" class="btn delete" title="Delete" data-toggle="tooltip"><i class="material-icons">&#xE872;</i></a>
                                </div>
                            </td>
                        </tr>
                       
                       
                                
                    </tbody>
                </table>
           
            </div>
        </div>  
    </div>
</div>