<div class="row mt-5" style="justify-content: center; " *ngIf="showside">
  <div class="col-xl-8 ">
      <div class="card easion-card" >
        <div class="card-header">
          <div class="easion-card-icon">
              <i class="fas fa-chart-bar"></i>
          </div>
          <div class="easion-card-title">Edit</div>
      

      </div>
          <div class="card-body ">
              
              <div class="register-form" style="text-align: right;">
                      
                  <div class="form-group">
                    <label>اسم الطبيب</label>
                    <div>
                      {{section.name}}
                    </div>
                    
                  </div>
                 
                  <div class="form-group">
                    <label> : مايعرض في الصفحة الرئيسية وصفحه الأطباء</label>
                    <div>
                      {{section.firstDiscription}}
                    </div>
                  </div>
                  <div class="form-group">
                    <label>:  الفقرات التعريفية للطبيب لصفحته الخاصه</label>
                    <div>
                      {{section.secondDiscription}}
                    </div>
                    <div>
                      {{section.thirdDiscription}}
                    </div>
                  </div>
                  <div class="form-group">
                    <label>الرقم النقابي </label>
                   <div>
                    {{section.referalNumber}}
                   </div>
                  </div>

           
                  <div class="form-group">
                    <label> : لينك الفيديو التعريفي الخاص بالطبيب</label>
                    <div>
                      <a  target="_blank" [href]="section.videoPath">
                         <i class="fa fa-play-circle" aria-hidden="true"></i>
                      </a>
                     </div>
                  </div>
                  
                  <div class="form-group">
                    <label>لينك للملف الشخصي في  التيكتوك</label>
                    <div>
                      <a  target="_blank" [href]="section.tiktokURL">
                         <i class="fa fa-play" aria-hidden="true"></i>
                      </a>
                     </div>
                  </div>
                  <div class="form-group">
                    <label>لينك للملف الشخصي في  الفيسبوك</label>
                    <div>
                      <a  target="_blank" [href]="section.facebookURL">
                         <i class="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                     </div>
                  </div>
                  <div class="form-group">
                    <label>لينك للملف الشخصي في  الانستجرام</label>
                    <div>
                      <a  target="_blank" [href]="section.instgramURL">
                         <i class="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                     </div>
                  </div>
                  
                  <div class="form-group">
                    <label>لينك للملف الشخصي في  لينكد ان</label>
                    <div>
                      <a  target="_blank" [href]="section.linkedInURL">
                         <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                     </div>
                  </div>
               
                  <!-- <div class="form-group">
                    <label>اضافه لقائمه الصفحه الرئيسية ؟</label>
                    <div>
                        <i *ngIf="section.homeDisplay" class="fa fa-check" aria-hidden="true"></i>
                        <i *ngIf="!section.homeDisplay" class="fas fa-times"></i>
                    </div>

                  </div> -->

                  <div class="form-group">
                    <label> : الصوره الشخصيه للطبيب المعروضه في الصفحه الرئيسية والصفحة الشخصية</label>
                            
                   <p class="mt-3">
                      <img [src]="section.homeImagePath" alt="" width="100">
                   </p>
                  </div>
                  <div class="form-group">
                    <label> : الصوره الشخصيه للطبيب في الصفحه المجمعة للاطباء</label>
                    <p class="mt-3">
                      <img [src]="section.personalImagePath" alt="" width="100">
                   </p> 
                  </div>
                  <div class="form-group">
                    <label> : الصوره الشخصيه للطبيب في القسم المشترك لكل الصفحات  </label>
                    <p class="mt-3">
                      <img [src]="section.exploreImagePath" alt="" width="100">
                   </p> 
                  </div>
                 
                  
                </div>
          </div>
      </div>
  
     
  </div>





</div>
