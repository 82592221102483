<div class="dash-nav" style="background-color: #e5e5e5;" *ngIf="showside">
    <header>
    
        <a href="index.html"><img src="../../../assets/img/Logo.png" width="120" height="120"></a>
    </header>

    <nav class="dash-nav-list">
        <a href="index.html" class="dash-nav-item">
            <i class="fas fa-home"></i>
             Dashboard 
        </a>
        <div class="dash-nav-dropdown">
            <a  class="dash-nav-item dash-nav-dropdown-toggle">
                <i class="fas fa-folder"></i> الصفحة الرئيسية </a>
            <div class="dash-nav-dropdown-menu">
                <a routerLink="/counter"  class="dash-nav-dropdown-item">الاحصائات الرقمية</a>
              
            </div>
        </div>
        <a routerLink="/Doctors" class="dash-nav-item">
            <i class="fas fa-users"></i> 
            الأطباء 
 
        </a>
        
        <a routerLink="/Celebrities" class="dash-nav-item">
            <i class="fas fa-camera"></i> 
            المشاهير 
 
        </a>
        <a routerLink="/Gallery" class="dash-nav-item">
            <i class="fas fa-images"></i> 
            معرض الصور 
 
        </a>
        <a routerLink="/Services" class="dash-nav-item">
            <i class="fas fa-th"></i> 
           الخدمات
 
        </a>
        
        <a routerLink="/Happy-Patients" class="dash-nav-item">
            <i class="fas fa-smile"></i> 
           مرضانا السعداء
 
        </a>
        <a routerLink="/Before-After" class="dash-nav-item">
            <i class="fas fa-exchange-alt"></i> 
           حالات قبل وبعد
 
        </a>
        <a routerLink="/All-Blogs" class="dash-nav-item">
            <i class="fas fa-file"></i> 
           مدونات
 
        </a>
        <a routerLink="/Questions" class="dash-nav-item">
            <i class="fas fa-question"></i> 
           الأسأله المتكرره
        </a>
        <a routerLink="/New-Patient" class="dash-nav-item">
            <i class="fas fa-address-card"></i> 
           حجوزات مرضى جدد
        </a>
        <a routerLink="/Old-Patient" class="dash-nav-item">
            <i class="fas fa-address-book"></i> 
           حجوزات مرضى متابعه
        </a>
        <a routerLink="/Tour-Patient" class="dash-nav-item">
            <i class="fas fa-book-reader"></i> 
           حجوزات  السياحه العلاجيه
        </a>
        <a routerLink="/subscribers" class="dash-nav-item">
            <i class="fas fa-chart-line"></i> 
           متابعين الموقع
        </a>
        
        
    </nav>
    
</div>