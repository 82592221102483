<div *ngIf="showside">

  
  <div class="row mt-5" style="justify-content: center; text-align: right;">
    <div class="col-xl-11 ">
  
      
  
  <div class="container-xl">
      <div class="table-responsive">
          <div class="table-wrapper">
              <div class="table-title">
                  <div class="row">
                      <div class="col-sm-3" style="text-align: initial;"><h2>Famouse <b>Details</b></h2></div>
                      <div class="col-sm-5">
                          <div class="search-box" style="width:inherit">
                              
                              <input  type="text" class="form-control"
                               placeholder="Search with name "
                               id="speciesNameSearch"
                                name="speciesNameSearch" 
                              (keyup.enter)="getSearchResult()"
                               [(ngModel)]="speciesName">
                              <a class="btn primary">
                                  <i class="fa fa-search" aria-hidden="true"></i>
                              </a>
                          </div>
                      </div>
                      <div class="col-sm-4">
                          <a class="btn btn-info add-new " href="/Celebrity-Editions" style="margin-left: auto;color: #fff;"> 
                              <i class="fa fa-plus"></i>                
                                Add Famouse
              
                          </a>
                      </div>
                  </div>
              </div>
              <table class="table table-striped table-hover table-bordered">
                  <thead>
                      <tr>
                          <th>#</th>
                          <th>
                              Photo   
                          </th>
                          <th>Name </th>
                         
                          <th>Actions</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of famouseList let i= index">
                          <td>{{i+1}}</td>
                          <td><img [src]="item.imagePath" width="100"></td>
                          <td>{{item.name}}</td>
                         
                          <td>
                            
                              <div style="display: inline-flex;">

                              <a [routerLink]="['/Celebrity-Editions', item.famousSectionID]"  class="btn view" title="view" data-toggle="tooltip"><i class="material-icons">&#xE417;</i></a>
                              <a (click)="deleteitem(item.famousSectionID)" class="btn delete" title="Delete" data-toggle="tooltip"><i class="material-icons">&#xE872;</i></a>
                              </div>
                          </td>
                      </tr>
                     
                     
                              
                  </tbody>
              </table>
         
          </div>
      </div>  
  </div>
  
  </div>
  </div>
  
  </div>
  