<div class="row mt-5" style="justify-content: center; " *ngIf="showside">
  <div class="col-xl-8 ">
        <div class="card easion-card" *ngIf="!isAddMode">
          <div class="card-header">
            <div class="easion-card-icon">
                <i class="fas fa-chart-bar"></i>
            </div>
            <div class="easion-card-title">Edit</div>
        

        </div>
            <div class="card-body ">
                
              <div class="register-form" style="text-align: right;">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">

                
                 
                  <div class="form-group">
                    <label>  صوره لحاله المريض</label>
                    <input id="upload" type="file"   class="form-control border-0" #file1 type="file" 
                    (change)="uploadFile1(file1.files)"  required 
                    formControlName="patientImagePath"
                    />      
                    <div *ngIf="submitted && f.patientImagePath.errors" class="invalid-feedback">
                      <div *ngIf="f.patientImagePath.errors.required">Image is Required</div>
                    </div>           
                   <!-- <p class="mt-3">
                      <img src="../../../assets/img/download (5).png" alt="Dr.Mohamed" width="100">
                   </p> -->
                  </div>
                  <div class="form-group">
                    <label> صوره الطبيب مع المريض </label>
                    <input id="upload" type="file"   class="form-control border-0" #file2 type="file" 
                    (change)="uploadFile2(file2.files)"  required 
                    formControlName="patientWithDoctorImagePath"
                    />      
                    <div *ngIf="submitted && f.patientWithDoctorImagePath.errors" class="invalid-feedback">
                      <div *ngIf="f.patientWithDoctorImagePath.errors.required">Image is Required</div>
                    </div> 
                  </div>
                  <div class="form-group mt-5 mb-5">
                    <label>التعليق</label>
                    <textarea
                      type="text"
                      formControlName="caught"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.caught.errors }"
                      cols="20" rows="5">

                    ></textarea>
                    <div *ngIf="submitted && f.caught.errors" class="invalid-feedback">
                      <div *ngIf="f.caught.errors.required">التعليق مطلوب</div>
                    </div>
                  </div>
                  
                  <div class="form-group">
                    <button type="submit" class="btn btn-primary mt-5">Update</button>
                
                  </div>
                </form>
            </div>
            </div>
        </div>
    
        <div class="card easion-card" *ngIf="isAddMode">
            <div class="card-header">
              <div class="easion-card-icon">
                  <i class="fas fa-chart-bar"></i>
              </div>
              <div class="easion-card-title">Add</div>
          
  
          </div>
              <div class="card-body ">
                  
                <div class="register-form" style="text-align: right;">
                  <form [formGroup]="form" (ngSubmit)="onSubmit()">

                    <div class="form-group">
                      <label for=""> اختر اسم الطبيب</label>
                      <select class="custom-select" (change)="changeDoctor($event)" formControlName="doctorID">
                        <option value="" disabled>Choose your the doctor</option>
                        <option *ngFor="let doctor of doctorList" [ngValue]="doctor.id">{{doctor.name}}</option>
                     </select>
                     <div *ngIf="isSubmitted && doctorID.errors?.required">
                      <sup>*</sup>Please enter choose doctor name
                   </div>
                    </div>
                   
                    <div class="form-group">
                      <label>  صوره لحاله المريض</label>
                      <input id="upload" type="file"   class="form-control border-0" #file1 type="file" 
                      (change)="uploadFile1(file1.files)"  required 
                      formControlName="patientImagePath"
                      />      
                      <div *ngIf="submitted && f.patientImagePath.errors" class="invalid-feedback">
                        <div *ngIf="f.patientImagePath.errors.required">Image is Required</div>
                      </div>           
                     <!-- <p class="mt-3">
                        <img src="../../../assets/img/download (5).png" alt="Dr.Mohamed" width="100">
                     </p> -->
                    </div>
                    <div class="form-group">
                      <label> صوره الطبيب مع المريض </label>
                      <input id="upload" type="file"   class="form-control border-0" #file2 type="file" 
                      (change)="uploadFile2(file2.files)"  required 
                      formControlName="patientWithDoctorImagePath"
                      />      
                      <div *ngIf="submitted && f.patientWithDoctorImagePath.errors" class="invalid-feedback">
                        <div *ngIf="f.patientWithDoctorImagePath.errors.required">Image is Required</div>
                      </div> 
                    </div>
                    <div class="form-group mt-5 mb-5">
                      <label>التعليق</label>
                      <textarea
                        type="text"
                        formControlName="caught"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.caught.errors }"
                        cols="20" rows="5">

                      ></textarea>
                      <div *ngIf="submitted && f.caught.errors" class="invalid-feedback">
                        <div *ngIf="f.caught.errors.required">التعليق مطلوب</div>
                      </div>
                    </div>
                    
                    <div class="form-group">
                      <button type="submit" class="btn btn-primary mt-5">Add</button>
                  
                    </div>
                  </form>
              </div>
              </div>
        </div>
    </div>

  

  
 
</div>
