import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertPromise } from 'selenium-webdriver';
import { AuthService } from 'src/app/ServicesAPis/Authorize/auth.service';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit  {

  form: any = {
    emailOrUserName: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  constructor(private authService: AuthService, private tokenStorage: TokenStorageService,private router: Router) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
    }
    this.reloadPage;
  }
  
  onSubmit(): void {
    const { emailOrUserName, password } = this.form;

    this.authService.login(emailOrUserName, password).subscribe(
      data => {
      
        this.tokenStorage.saveToken(data.token);
        // this.tokenStorage.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        // this.roles = this.tokenStorage.getUser().roles;
        // this.reloadPage();
        this.router.navigate(['/'])
  .then(() => {
    window.location.reload();
  });

      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }
  reloadPage(): void {
    window.location.reload();
  }
}
