import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './static/navbar/navbar.component';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { CounterHomePageComponent } from './counter-home-page/counter-home-page.component';
import { AllDoctorsComponent } from './doctors/all-doctors/all-doctors.component';
import { GetDoctorByIDComponent } from './doctors/get-doctor-by-id/get-doctor-by-id.component';
import { AllCelebritiesComponent } from './Famouse/all-celebrities/all-celebrities.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AllServicesComponent } from './services/all-services/all-services.component';
import { DoctorArticleComponent } from './doctors/doctor-article/doctor-article.component';
import { DoctorPatientComponent } from './doctors/doctor-patient/doctor-patient.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ArticalesEditionsComponent } from './doctors/articales-editions/articales-editions.component';
import { PatientEditionComponent } from './doctors/patient-edition/patient-edition.component';
import { CelebrityEditionComponent } from './Famouse/celebrity-edition/celebrity-edition.component';
import { AllpatientreviewComponent } from './Patients/allpatientreview/allpatientreview.component';
import { RevieweditionComponent } from './Patients/reviewedition/reviewedition.component';
import { ServicesEditionComponent } from './services/services-edition/services-edition.component';
import { BeforeandafterstatusComponent } from './Patients/beforeandafterstatus/beforeandafterstatus.component';
import { BeforeandaftereditionComponent } from './Patients/beforeandafteredition/beforeandafteredition.component';
import { BlogsComponent } from './doctors/blogs/blogs.component';
import { BlogEditionComponent } from './doctors/blog-edition/blog-edition.component';
import { QAComponent } from './Patients/qa/qa.component';
import { QAEditionComponent } from './Patients/qaedition/qaedition.component';
import { NewServeFormComponent } from './forms/new-serve-form/new-serve-form.component';
import { OldServeFormComponent } from './forms/old-serve-form/old-serve-form.component';
import { TourServeFormComponent } from './forms/tour-serve-form/tour-serve-form.component';
import { SubscribeeFormComponent } from './forms/subscribee-form/subscribee-form.component';
import { LoginComponent } from './Authorization/login/login.component';
import { AdminsComponent } from './Authorization/Admin/admins/admins.component';
import { AdminAditionsComponent } from './Authorization/Admin/admin-aditions/admin-aditions.component';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { DoctorEditionComponent } from './doctors/doctor-edition/doctor-edition.component';


const routes: Routes = [
  { path: '', component: DashboardHomeComponent },
  { path: 'login', component: LoginComponent },

  {path:'counter', component:CounterHomePageComponent},
  { path: 'Doctors', component: AllDoctorsComponent },
  {path:'Doctor-Edition', component:DoctorEditionComponent},
  {path:'Doctor-Edition/:id', component:DoctorEditionComponent},
  {path:'Doctor-Details/:id', component:GetDoctorByIDComponent},
  { path: 'Gallery', component: GalleryComponent },
  { path: 'Services', component: AllServicesComponent },
  { path: 'Doctors-Articles', component: DoctorArticleComponent },
  { path: 'Doctors-Patients/:id', component: DoctorPatientComponent },
  { path: 'Blog-Details/:id', component: ArticalesEditionsComponent },
  // { path: 'Article-Editions/:id', component: ArticalesEditionsComponent },
  { path: 'Patient-Editions', component: PatientEditionComponent },
  { path: 'Patient-Editions/:id', component: PatientEditionComponent },
  { path: 'Celebrities', component: AllCelebritiesComponent },
  { path: 'Celebrity-Editions', component: CelebrityEditionComponent },
  { path: 'Celebrity-Editions/:id', component: CelebrityEditionComponent },
  { path: 'Happy-Patients', component: AllpatientreviewComponent },
  { path: 'Patient-Review-Editions', component: RevieweditionComponent },
  { path: 'Patient-Review-Editions/:id', component: RevieweditionComponent },
  { path: 'Questions', component: QAComponent },
  { path: 'Q&A-Editions', component: QAEditionComponent },
  { path: 'Q&A-Editions/:id', component: QAEditionComponent },
  { path: 'Service-Edition/:id', component: ServicesEditionComponent },
  { path: 'Before-After', component: BeforeandafterstatusComponent },
  { path: 'Before-After-Edition', component: BeforeandaftereditionComponent },
    { path: 'Before-After-Edition/:id', component: BeforeandaftereditionComponent },
    { path: 'All-Blogs', component: BlogsComponent },
    { path: 'All-Blogs/:id', component: BlogsComponent },
    { path: 'Blog-Edition', component: BlogEditionComponent },
    { path: 'Blog-Edition/:id', component: BlogEditionComponent },
    { path: 'New-Patient', component: NewServeFormComponent },
    { path: 'New-Patient/:id', component: NewServeFormComponent },

    { path: 'Old-Patient', component: OldServeFormComponent },
    { path: 'Old-Patient/:id', component: OldServeFormComponent },

    { path: 'Tour-Patient', component: TourServeFormComponent },
    { path: 'Tour-Patient/:id', component: TourServeFormComponent },

    { path: 'subscribers', component: SubscribeeFormComponent },
    { path: 'LogIn', component: LoginComponent },
    { path: 'Admin', component: AdminsComponent },
    { path: 'Admin_Edition', component: AdminAditionsComponent },
    { path: 'Admin_Edition/:id', component: AdminAditionsComponent }



















];


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DashboardHomeComponent,
    CounterHomePageComponent,
    AllDoctorsComponent,
    GetDoctorByIDComponent,
    AllCelebritiesComponent,
    GalleryComponent,
    AllServicesComponent,
    DoctorArticleComponent,
    DoctorPatientComponent,
    ArticalesEditionsComponent,
    PatientEditionComponent,
    CelebrityEditionComponent,
    AllpatientreviewComponent,
    RevieweditionComponent,
    ServicesEditionComponent,
    BeforeandafterstatusComponent,
    BeforeandaftereditionComponent,
    BlogsComponent,
    BlogEditionComponent,
    QAComponent,
    QAEditionComponent,
    NewServeFormComponent,
    OldServeFormComponent,
    TourServeFormComponent,
    SubscribeeFormComponent,
    LoginComponent,
    AdminsComponent,
    AdminAditionsComponent,
    DoctorEditionComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RouterModule.forRoot(routes),
    NgbModule
  ],
  exports: [RouterModule],
  providers: [authInterceptorProviders],

  bootstrap: [AppComponent]
})
export class AppModule { }
