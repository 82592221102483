import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Gallery_Model } from 'src/app/Models/Gallery_Model';
import { Status_Model } from 'src/app/Models/Status_Model';
import { environment } from '../../../environments/environment';


const baseUrl=environment.baseUrl+'admin/v1/service/all/images'
@Injectable({
  providedIn: 'root'
})
export class StatusService {

  section:Status_Model
  constructor(private http: HttpClient) { }

  ngOnInit() {          
}
addStatus(status : Status_Model[])
{
  return this.http.post(baseUrl,status);

} 

getAllStatus(): Observable<any> {
  return this.http.get<any>(baseUrl+'?sortby=desc');

}
getAllStatusAsc(): Observable<any> {
  return this.http.get<any>(baseUrl+'?sortby=asc');
}
// http://mohamed555dsa-001-site1.btempurl.com/admin/v1/service/all/image/5d98689f-5b65-4e98-a39a-4ff4b6907ccb

updateStatus(id:any,status:any): Observable<any> {
  return this.http.put(`${environment.baseUrl}admin/v1/service/all/image/${id}`,status);
}
getStatusByID(item:Status_Model){

  this.section=item
  return this.section;
}
getsection(){
  return this.section;

}
// {{URL}}/admin/v1/service/image/{{serviceimageid}}
deleteStatus(id:any): Observable<any> {
 
  return this.http.delete(`${environment.baseUrl}admin/v1/service/image/${id}`);
  
}




}
