import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Reviews_Model } from 'src/app/Models/Reviews_Model';
import { Vedio_Model } from 'src/app/Models/Vedio_Model';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { GalleryService } from 'src/app/ServicesAPis/Gallery/gallery.service';
import { RewiewService } from 'src/app/ServicesAPis/HappyPatient/rewiew.service';
import { VedioService } from 'src/app/ServicesAPis/HappyPatient/vedio.service';

@Component({
  selector: 'app-allpatientreview',
  templateUrl: './allpatientreview.component.html',
  styleUrls: ['./allpatientreview.component.css']
})
export class AllpatientreviewComponent implements OnInit {
  AddClicked: Boolean = false;
  speciesName: string = "";

  showside = false;
  isLoggedIn = false;
  vedioList:any[]=[];
  reviewList:any[]=[];

  vedioDataList:Vedio_Model[]=[];
  reviewDataList:Reviews_Model[]=[];

  form :FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder ,
    private router: Router,
    private tokenStorageService: TokenStorageService ,  
    private vedioService: VedioService,
    private reviewsService: RewiewService) {}
  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
     
      this.showside=true;
      
    }
    else{
      this.router.navigate(['/login']);

    }
    
    this.form = this.formBuilder.group(
      {
        videoPath: ['', Validators.required]
        // isFavorite:[false],
        // type:[''],
        // imagePath:[''],
        // name:[''],
        // job:[''],
        // comment:['']
      
  
      }
    );
    this.retrieveAllVedios();
    this.retrieveAllReviews();


  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
this.vedioDataList.push(this.form.value)
    this.vedioService.addVedio(this.vedioDataList).subscribe((data:any)=>{
      this.router.navigate(['/Happy-Patients'])
      .then(() => {
        window.location.reload();
      });
    }); 
   }
   retrieveAllVediosASc(){
    this.vedioService.getAllVediosASC()
    .subscribe(
      response => {
        this.vedioList = response;
       
      },
      error => {
        //console.log(error);
      });
   }
   retrieveAllReviewAsc(){
    this.reviewsService.getAllReviewsASC()
    .subscribe(
      response => {
        this.reviewList = response;
       
      },
      error => {
        //console.log(error);
      });
   }
  AddViewForm():void{
    this.AddClicked = true;
  
  }
  retrieveAllVedios(){
    this.vedioService.getAllVedios()
    .subscribe(
      response => {
        this.vedioList = response;
       
      },
      error => {
        //console.log(error);
      });
  }
  retrieveAllReviews(){
    this.reviewsService.getAllReviews()
    .subscribe(
      response => {
        this.reviewList = response;
       
      },
      error => {
        //console.log(error);
      });
  }
  retrieveAllReviewsIsFav(){
    this.reviewsService.getAllReviewsIsFav()
    .subscribe(
      response => {
        this.reviewList = response;
       
      },
      error => {
        //console.log(error);
      });
  }
  retrieveAllReviewIsFavAsc(){
    this.reviewsService.getAllReviewsIsFavASC()
    .subscribe(
      response => {
        this.reviewList = response;
       
      },
      error => {
        //console.log(error);
      });
   }
  deleteVedio(id){
    this.vedioService.deleteVedio(id).subscribe((data:any)=>{
      this.retrieveAllVedios();
  });
}
deleteReview(id){
  this.reviewsService.deleteReview(id).subscribe((data:any)=>{
    this.retrieveAllReviews();
});
}
addFavorit(id , isfav , review){
  this.reviewsService.isFavorit(id , isfav , review).subscribe((data:any)=>{
    this.retrieveAllReviews();

  });
}
getSearchResult() {

  this.reviewsService.getSearchResult(this.speciesName).subscribe(response => {
    this.reviewList = response;
  
  });
}
}
