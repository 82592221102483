import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { DoctorService } from 'src/app/ServicesAPis/Doctor/doctor.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';

@Component({
  selector: 'app-doctor-edition',
  templateUrl: './doctor-edition.component.html',
  styleUrls: ['./doctor-edition.component.css']
})
export class DoctorEditionComponent implements OnInit {
  isAddMode: boolean;
  id:string;
  form :FormGroup;
  submitted = false;
  isLoggedIn = false;
  showside = false;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService ,
    private doctorService : DoctorService,
    private  imageService:UploadImageService
  ) {}


  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
    this.form = this.formBuilder.group(
      {
        name: ['', Validators.required],
        firstDiscription: [
          '',
          [
            Validators.required,
            Validators.minLength(100),
            Validators.maxLength(310)
          ]
        ],
        secondDiscription: [
          '',
          [
            Validators.required,
            Validators.minLength(100),
            Validators.maxLength(310)
          ]
        ],
        thirdDiscription: [
          '',
          [
            Validators.required,
            Validators.minLength(100),
            Validators.maxLength(288)
          ]
        ],
        referalNumber: ['', Validators.required],
        homeImagePath: [Validators.required],
        personalImagePath: [Validators.required],
        exploreImagePath: [Validators.required],
        videoPath: ['',Validators.required],
        tiktokURL: ['',Validators.required],
        facebookURL: ['',Validators.required],
        instgramURL: ['',Validators.required],
        linkedInURL: ['',Validators.required],
        // homeDisplay: [false,Validators.required],


      }
    );
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    if (!this.isAddMode) {
      this.form.get('homeImagePath').setValue(File, {emitModelToViewChange: false});
      this.form.get('personalImagePath').setValue(File, {emitModelToViewChange: false});
      this.form.get('exploreImagePath').setValue(File, {emitModelToViewChange: false});

        this.doctorService.getDoctorByID(this.id)
            .subscribe(x => {

              this.form.patchValue(x);  
          });
      
  }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.isAddMode) {

      this.creatDoctor();

  } else {
      this.updateDoctor();
  } 
 }
 private creatDoctor():void{
  this.form.value.homeImagePath=this.path1;
  this.form.value.personalImagePath=this.path2;
  this.form.value.exploreImagePath=this.path3;

  this.doctorService.addDoctor(this.form.value).subscribe((data:any)=>{
    this.router.navigate(['/Doctors']);
    
  });

}
private updateDoctor():void{
  this.doctorService.updateDoctor(this.id,this.form.value).subscribe((data:any)=>{
    this.router.navigate(['/Doctor-Details', this.id]);

   
  });
}
path1:string
uploadFile1= (files) => {
  this.imageService.uploadFile(files).subscribe((data:any)=>{
    this.form.value.homeImagePath=data.path
     this.path1=data.path;


  });
  }
  path2:string

  uploadFile2= (files) => {
    this.imageService.uploadFile(files).subscribe((data:any)=>{
      this.form.value.personalImagePath=data.path
   this.path2=data.path
  
  
    });
    }
    path3:string
    uploadFile3= (files) => {
      this.imageService.uploadFile(files).subscribe((data:any)=>{
        this.form.value.exploreImagePath=data.path
    this.path3=data.path;
    
    
      });
      }
}