import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { DoctorService } from 'src/app/ServicesAPis/Doctor/doctor.service';
import { PatientsService } from 'src/app/ServicesAPis/Doctor/patients.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';

@Component({
  selector: 'app-patient-edition',
  templateUrl: './patient-edition.component.html',
  styleUrls: ['./patient-edition.component.css']
})
export class PatientEditionComponent implements OnInit {
  isAddMode: boolean;
  id:string;
  form :FormGroup;
  submitted = false;
  isLoggedIn = false;
  showside = false;
  doctorList:any[]=[];
  path1:string;
  path2:string;
  splitList:any[]=[]

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService ,
    private patientService : PatientsService,
    private doctorService : DoctorService,
    private  imageService:UploadImageService
  ) {}


  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
    this.form = this.formBuilder.group(
      {
        doctorID: ['', Validators.required],
        patientImagePath: ['', Validators.required],
        patientWithDoctorImagePath: ['', Validators.required],
        caught: ['', Validators.required]


      }
    );
  
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    if (!this.isAddMode) {
      this.form.get('patientImagePath').setValue(File, {emitModelToViewChange: false});
      this.form.get('patientWithDoctorImagePath').setValue(File, {emitModelToViewChange: false});

          
     this.form.patchValue(this.patientService.getPatent())
  }
  this.getDoctorMenue()
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.isAddMode) {
      this.creatPatient();

  } else {
      this.updatePatient();
  } 
 }
 private creatPatient():void{
  this.form.value.patientImagePath=this.path1;
  this.form.value.patientWithDoctorImagePath=this.path2;

  this.patientService.addPatient(this.form.value).subscribe((data:any)=>{
    this.router.navigate(['/Doctors-Patients',this.form.value.doctorID]);
    
  });
 }

 getDoctorMenue():void{
  this.doctorService.getAllDoctorsForDropDown()
  .subscribe(
    response => {
      this.doctorList = response;
    },
    error => {
      //console.log(error);
    });
 }
  // Choose city using select dropdown
  changeDoctor(e) {
    this.splitList=e.target.value.split(': ');
    this.doctorID.setValue(this.splitList[1])
  }
  get doctorID() {
    return this.form.get('doctorID');
  }
 private updatePatient():void{
  // this.doctorID.setValue(this.id)

  this.patientService.updatePatient(this.id,this.form.value).subscribe((data:any)=>{
    this.router.navigate(['/Doctors-Patients',this.form.value.doctorID]);

   
  });
 }
uploadFile1= (files) => {
  this.imageService.uploadFile(files).subscribe((data:any)=>{
    this.form.value.patientImagePath=data.path
     this.path1=data.path;


  });
  }

  uploadFile2= (files) => {
    this.imageService.uploadFile(files).subscribe((data:any)=>{
      this.form.value.patientWithDoctorImagePath=data.path
   this.path2=data.path
  
  
    });
    }

}
