<div class="form-screen">
    <a href="index.html" class="easion-logo"><img src="../../../assets/img/Logo.png" alt="" srcset="" width="200" height="200"></a>
    <div class="card account-dialog">
        <div class="card-header bg-primary text-white"> Please sign in </div>
        <div class="card-body">
            <form
      *ngIf="!isLoggedIn"
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      novalidate
    >
      <div class="form-group">
        <label for="emailOrUserName">User Name</label>
        <input
          type="text"
          class="form-control"
          name="emailOrUserName"
          [(ngModel)]="form.emailOrUserName"
          required
          #emailOrUserName="ngModel"
        />
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="emailOrUserName.errors && f.submitted"
        >
        emailOrUserName is required!
    </div>
  </div>
  <div class="form-group">
    <label for="password">Password</label>
    <input
      type="password"
      class="form-control"
      name="password"
      [(ngModel)]="form.password"
      required
     
      #password="ngModel"
    />
    <div
      class="alert alert-danger"
      role="alert"
      *ngIf="password.errors && f.submitted"
    >
      <div *ngIf="password.errors.required">Password is required</div>
      <!-- <div *ngIf="password.errors.minlength">
        minlength="6"
        Password must be at least 6 characters
      </div> -->
    </div>
  </div>
  <div class="form-group">
    <button class="btn btn-primary btn-block">
      Login
    </button>
  </div>
  <div class="form-group">
    <div
      class="alert alert-danger"
      role="alert"
      *ngIf="f.submitted && isLoginFailed"
    >
      Login failed: {{ errorMessage }}
    </div>
  </div>
</form>
        </div>
    </div>
</div>