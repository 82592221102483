import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';




const baseUrl=environment.baseUrl+'admin/v1/appointmenttpe';
const delUrl=environment.baseUrl+'admin/v1/appointment';
const subUrl=environment.baseUrl+'admin/v1/subscribtion';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(private http: HttpClient) { }

  ngOnInit() {          
  } 
  getAllEmails(): Observable<any> {
    return this.http.get<any>(subUrl);
  }
  deleteEmails(id:any): Observable<any> {
    return this.http.delete(`${subUrl}/${id}`);
  }
getAllformsTour(): Observable<any> {
  return this.http.get<any>(baseUrl+"/tourism/appointment");
}
getAllformsNew(): Observable<any> {
  return this.http.get<any>(baseUrl+"/new/appointment");
}
getAllformsExist(): Observable<any> {
  return this.http.get<any>(baseUrl+"/exist/appointment");
}
getSearchResultTour(query:any): Observable<any> {
  return this.http.get<any>(baseUrl+"/tourism/appointment?search="+query);
}
getSearchResultNew(query:any): Observable<any> {
  return this.http.get<any>(baseUrl+"/new/appointment?search="+query);
}
getSearchResultExist(query:any): Observable<any> {
  return this.http.get<any>(baseUrl+"/exist/appointment?search="+query);
}
getFormID(id:any): Observable<any> {
  return this.http.get(`${delUrl}/${id}`);
}
deleteForm(id:any): Observable<any> {
  return this.http.delete(`${delUrl}/${id}`);
}
}
