<div class="row mt-5" style="justify-content: center; text-align: right;">
  <div class="row">
   
  </div>
    <div class="col-xl-8 ">
        <div class="card easion-card" *ngIf="!isAddMode">
          <div class="card-header">
            <div class="easion-card-icon">
                <i class="fas fa-chart-bar"></i>
            </div>
            <div class="easion-card-title">Edit</div>
        

        </div>
            <div class="card-body ">
                
              <label for="">Name</label>
              <p>
                {{famouswSection.name}}
              </p>
              
              <label for="">Cover Image</label>
              <p>
                <img [src]="famouswSection.imagePath" alt="" style="width: 100px;">
              </p>
              <label >Album Image</label>
              <br>
              <div style="display: inline-flex;">
              <p *ngFor="let item of famouswSection.famousSectionImages">
                <img [src]="item.imagePath" alt="" style="width: 100px;">
              </p>
              </div>
             
            </div>
        </div>
    
        <div class="card easion-card" *ngIf="isAddMode">
            <div class="card-header">
              <div class="easion-card-icon">
                  <i class="fas fa-chart-bar"></i>
              </div>
              <div class="easion-card-title">Add</div>
          
  
          </div>
              <div class="card-body ">
                  
                  <div class="register-form">
                      <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        
                        <div class="form-group">
                            <label>Name</label>
                            <input
                              type="text"
                              formControlName="name"
                              class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
    
                            />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                              <div *ngIf="f.name.errors.required">الاسم مطلوب</div>
                            </div>
                        </div>
                        
                        <div class="form-group">
                          <label> Cover Image</label>
                          <input id="upload" type="file"   class="form-control border-0" #file1 type="file" 
                          (change)="uploadFile1(file1.files)"  required 
                          formControlName="imagePath"
                          />      
                          <div *ngIf="submitted && f.imagePath.errors" class="invalid-feedback">
                            <div *ngIf="f.imagePath.errors.required">Image is Required</div>
                          </div>           
                         <!-- <p class="mt-3">
                            <img src="../../../assets/img/download (5).png" alt="Dr.Mohamed" width="100">
                         </p> -->
                        </div>

                        <div class="form-group">
                          <h3>Images of album</h3>
                          <div
                          formArrayName="famousSectionImages"
                          *ngFor="let section of form.get('famousSectionImages')['controls']; let i = index;"
                        >
                        <div [formGroupName]="i">
                         
                      <div class="form-group">
                        <label>Image</label>
                        <input #myInput id="upload" type="file"   class="form-control border-0" #file2 type="file" 
                        (change)="uploadFile2(file2.files , i)"  required 
                        formControlName="imagePath" placeholder="upload image section "
                        /> 
                       
                    </div>
                        

                        </div>
                      </div>
                        <button type="button" class="btn btn-danger" (click)="addSection()">Add section</button>
                        </div>
                        <div class="form-group">
                          <button type="submit" class="btn btn-primary mr-5">add </button>
                          
                        </div>
                      </form>
                    </div>
              </div>
        </div>
    </div>

  

  
 
</div>
