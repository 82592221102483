import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';
import { GalleryService } from 'src/app/ServicesAPis/Gallery/gallery.service';
import { ServicesService } from 'src/app/ServicesAPis/Services/services.service';

@Component({
  selector: 'app-all-services',
  templateUrl: './all-services.component.html',
  styleUrls: ['./all-services.component.css']
})
export class AllServicesComponent implements OnInit {
  showside = false;
  isLoggedIn = false;
  serviceList:any[]=[];
  speciesName: string = "";


  constructor(
    private tokenStorageService: TokenStorageService ,  
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,     
    private router: Router,
    private servicesService: ServicesService,
    private  imageService:UploadImageService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;
     
    }
    else{
      this.router.navigate(['/login']);

    }
    this.retrieveAllServices();

  }

  retrieveAllServices():void{
    this.servicesService.getAllServices()
    .subscribe(
      response => {
        this.serviceList = response;
      },
      error => {
        //console.log(error);
      });
  }
  getSearchResult() {

    this.servicesService.getResult(this.speciesName).subscribe(response => {
      this.serviceList = response;
    
    });
  }
}
