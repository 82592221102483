import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Admin_Model } from 'src/app/Models/Admin_Model';
import { Famouse_Model } from 'src/app/Models/Famouse_Model';
import { environment } from '../../../environments/environment';


const baseUrl=environment.baseUrl+'admin/v1/famoussection';
@Injectable({
  providedIn: 'root'
})
export class FamouseService {

  constructor(private http: HttpClient) { }

  ngOnInit() {          
}
addFamouse(item : Famouse_Model): Observable<any> 
{
  return this.http.post(baseUrl,item);
} 
getAllFamouse(): Observable<any> {
  return this.http.get<any>(baseUrl);
}

getResult( query:any): Observable<any> {
  return this.http.get<any>(`${baseUrl}?search=${query}`);
}
getFamouseByID(id:any): Observable<any> {
  return this.http.get(`${baseUrl}/${id}`);
}

deleteFamouse(id:any): Observable<any> {
  return this.http.delete(`${baseUrl}/${id}`);
}
updateFamouse(id:any,section:any): Observable<any> {
  return this.http.put(`${baseUrl}/${id}`,section);
}



}
