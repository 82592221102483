import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { FormsService } from 'src/app/ServicesAPis/Forms/forms.service';

@Component({
  selector: 'app-subscribee-form',
  templateUrl: './subscribee-form.component.html',
  styleUrls: ['./subscribee-form.component.css']
})
export class SubscribeeFormComponent implements OnInit {
  AddClicked: Boolean = false;
  formList:any[]=[];
  showside = false;
  isLoggedIn = false;
  isAddMode: boolean;
  birth:string[]=[];
  id:string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorageService: TokenStorageService ,  
    private formService: FormsService,
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
     
      this.showside=true;
      
    }
    else{
      this.router.navigate(['/login']);

    }
    this.retrieveAllEmail();

  }
  retrieveAllEmail(){
    this.formService.getAllEmails()
    .subscribe(
      response => {
        this.formList = response;
       
      },
      error => {
        //console.log(error);
      });
  }
  deleteitem(id:any){
    this.formService.deleteEmails(id).subscribe((data)=>{
    this.retrieveAllEmail();
    });

  }
}
