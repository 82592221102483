import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { FormsService } from 'src/app/ServicesAPis/Forms/forms.service';

@Component({
  selector: 'app-old-serve-form',
  templateUrl: './old-serve-form.component.html',
  styleUrls: ['./old-serve-form.component.css']
})
export class OldServeFormComponent implements OnInit {
  AddClicked: Boolean = false;
  speciesName: string = "";
  formList:any[]=[];
  showside = false;
  isLoggedIn = false;
  isAddMode: boolean;
  birth:string[]=[];
  date:string[]=[];
  id:string;
  formsection:any
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorageService: TokenStorageService ,  
    private formService: FormsService,
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
     
      this.showside=true;
      
    }
    else{
      this.router.navigate(['/login']);

    }
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    // Get the data by Id
    if (!this.isAddMode) {
      // this.form.get('imagePath').setValue(File, {emitModelToViewChange: false});

      this.formService.getFormID(this.id)
          .pipe()
          .subscribe(x =>{
            this.formsection=x;

            this.date=this.formsection.reservationDate.split("T");
            this.formsection.reservationDate=this.date[0];
            //console.log(this.date);

          } );
  }
    this.retrieveAllForms();

  }
  retrieveAllForms(){
    this.formService.getAllformsExist()
    .subscribe(
      response => {
        this.formList = response;
       
      },
      error => {
        //console.log(error);
      });
  }
  getSearchResult() {

    this.formService.getSearchResultExist(this.speciesName).subscribe(response => {
      this.formList = response;
    
    });
  }
  deleteitem(id:any){
    this.formService.deleteForm(id).subscribe((data)=>{
    this.retrieveAllForms();
    });

  }

}

