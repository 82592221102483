
    <main class="dash-content" *ngIf="showside">
        <div class="container-fluid">
            <div class="row dash-row">
                <div class="col-xl-4">
                    <div class="stats stats-primary">
                        <h3 class="stats-title"> Visitors </h3>
                        <div class="stats-content">
                            <div class="stats-icon">
                                <i class="fas fa-user"></i>
                            </div>
                            <div class="stats-data">
                                <div class="stats-number">114</div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="stats stats-success ">
                        <h3 class="stats-title"> Subscribers </h3>
                        <div class="stats-content">
                            <div class="stats-icon">
                                <i class="fas fa-user"></i>
                            </div>
                            <div class="stats-data">
                                <div class="stats-number">114</div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="stats stats-danger">
                        <h3 class="stats-title"> Submited Form </h3>
                        <div class="stats-content">
                            <div class="stats-icon">
                                <i class="fas fa-user"></i>
                            </div>
                            <div class="stats-data">
                                <div class="stats-number">5</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6">
                    <div class="card easion-card">
                        <div class="card-header">
                            <div class="easion-card-icon">
                                <i class="fas fa-bell"></i>
                            </div>
                            <div class="easion-card-title"> Reservation Forms </div>
                        </div>
                        <div class="card-body ">
                            <div class="notifications">
                                <a href="#!" class="notification">
                                    <div class="notification-icon">
                                        <i class="fas fa-inbox"></i>
                                    </div>
                                    <div class="notification-text">ِahmed</div>
                                    <span class="notification-time">time and date of reserve</span>
                                </a>
                                <a href="#!" class="notification">
                                    <div class="notification-icon">
                                        <i class="fas fa-inbox"></i>
                                    </div>
                                    <div class="notification-text">mohammed</div>
                                    <span class="notification-time">time</span>
                                </a>
                                <a href="#!" class="notification">
                                    <div class="notification-icon">
                                        <i class="fas fa-inbox"></i>
                                    </div>
                                    <div class="notification-text">Ali</div>
                                    <span class="notification-time">datetime</span>
                                </a>
                                <div class="notifications-show-all">
                                    <a href="#!">Show all</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   <div class="col-xl-6">
                    <div class="card easion-card">
                        <div class="card-header">
                            <div class="easion-card-icon">
                                <i class="fas fa-bell"></i>
                            </div>
                            <div class="easion-card-title"> Blogs </div>
                        </div>
                        <div class="card-body ">
                            <div class="notifications">
                                <a href="#!" class="notification">
                                    <div class="notification-icon">
                                        <i class="fas fa-inbox"></i>
                                    </div>
                                    <div class="notification-text">Blog 1</div>
                                    <span class="notification-time">21 days ago</span>
                                </a>
                                <a href="#!" class="notification">
                                    <div class="notification-icon">
                                        <i class="fas fa-inbox"></i>
                                    </div>
                                    <div class="notification-text">Blog 2</div>
                                    <span class="notification-time">21 days ago</span>
                                </a>
                                <a href="#!" class="notification">
                                    <div class="notification-icon">
                                        <i class="fas fa-inbox"></i>
                                    </div>
                                    <div class="notification-text">Blog 3</div>
                                    <span class="notification-time">21 days ago</span>
                                </a>
                                <div class="notifications-show-all">
                                    <a href="#!">Show all</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
