import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/ServicesAPis/Admin/admin.service';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.css']
})
export class AdminsComponent implements OnInit {
  isLoggedIn = false;
  showside = false;
  adminList:any[]=[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService,
    private adminService : AdminService

  ) { }

  ngOnInit(): void {
    
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
    this.retrieveAllAdmins();

  }
  retrieveAllAdmins(): void {
    
    this.adminService.getAllAdmins()
    .subscribe(
      response => {
        this.adminList = response;
      },
      error => {
        //console.log(error);
      });
  }
  deleteAdmin(id) {
    this.adminService.deleteAdmin(id).subscribe((data:any)=>{
    this.retrieveAllAdmins();

  });
  }
}
