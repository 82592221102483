import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Blog_Model } from 'src/app/Models/Blog_Model';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { BlogsService } from 'src/app/ServicesAPis/Blogs/blogs.service';
import { DoctorService } from 'src/app/ServicesAPis/Doctor/doctor.service';
import { getAllJSDocTags } from 'typescript';

@Component({
  selector: 'app-articales-editions',
  templateUrl: './articales-editions.component.html',
  styleUrls: ['./articales-editions.component.css']
})
export class ArticalesEditionsComponent implements OnInit {
  isAddMode: boolean;
  id:string;
  submitted = false;
  blogForm: FormGroup;
  isLoggedIn = false;
  showside = false;
  detailsList:Blog_Model[]=[];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private blogServices:BlogsService,
    private doctorService : DoctorService,
    private tokenStorageService: TokenStorageService ,

  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
   
    this.id = this.route.snapshot.params['id'];
  
    this.getBlog();
  }
  
  getBlog():void{

    this.blogServices.getBlogByID(this.id).subscribe(x => {
      this.detailsList=x;

    });
  }
}
