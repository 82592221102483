import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { QAService } from 'src/app/ServicesAPis/QA/qa.service';

@Component({
  selector: 'app-qa',
  templateUrl: './qa.component.html',
  styleUrls: ['./qa.component.css']
})
export class QAComponent implements OnInit {
  AddClicked: Boolean = false;
  index:number;
  alltypes:string;
  speciesName: string = "";
  isLoggedIn = false;
  showside = false;
  username?: string;
  form :FormGroup;
  submitted = false;
  qaList:any[]=[];
  typeList:any[]=[];
  selectedLevel:string;
  arraytype= new Array();
  uniqueList=new Array()
  constructor(
    private tokenStorageService: TokenStorageService ,  
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,     
    private router: Router,
    private QAService: QAService,
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;
     
    }
    else{
      this.router.navigate(['/login']);

    }
    this.form = this.formBuilder.group(
      {
        question: ['', Validators.required],
        answer: ['', Validators.required],
        type: ['', Validators.required]
      


      }
    );

    this.retrieveAllQA();
  }

  retrieveAllQA():void{
    this.QAService.getAllQABy()
    .subscribe(
      response => {
        this.qaList = response;
        this.typeList=response

        for (let item of this.typeList) {
  
          this.arraytype.push(item.type)

      }
      this.uniqueList = [...new Set(this.arraytype)]
      

      },
      error => {
        //console.log(error);
      });
     
  }
  selected(){
    this.QAService.getAllQAByType(this.selectedLevel)
    .subscribe(
      response => {
        this.qaList = response;
      },
      error => {
        //console.log(error);
      });
  }
 

  deleteQA(id) {
    this.QAService.deleteQA(id).subscribe((data:any)=>{
    this.retrieveAllQA();

  });
  }
  getSearchResult() {

    this.QAService.getAllQAByTypeWithSearch(this.speciesName).subscribe(response => {
      this.qaList = response;
    
    });
  }
 
}
