import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-article',
  templateUrl: './doctor-article.component.html',
  styleUrls: ['./doctor-article.component.css']
})
export class DoctorArticleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
