import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Gallery_Model } from '../Models/Gallery_Model';
import { ImageList_Model } from '../Models/ImageList_Model';
import { Image_Model } from '../Models/Image_Model';
import { TokenStorageService } from '../ServicesAPis/Authorize/token-storage.service';
import { UploadImageService } from '../ServicesAPis/files/upload-image.service';
import { GalleryService } from '../ServicesAPis/Gallery/gallery.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  imageListObj=new ImageList_Model('galleryID')
  AddClicked: Boolean = false;
  index:number;
  splitList=[]
  isLoggedIn = false;
  showside = false;
  username?: string;
  form :FormGroup;
  submitted = false;
  imagesList:any[]=[];
  imagesListData:Gallery_Model[]=[];
  delteList:ImageList_Model[]=[];
  getbody:Image_Model;

  sortby:string='desc';

  constructor(
    private tokenStorageService: TokenStorageService ,  
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,     
    private router: Router,
    private galleryService: GalleryService,
    private  imageService:UploadImageService) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;
     
    }
    else{
      this.router.navigate(['/login']);

    }
    this.form = this.formBuilder.group(
      {
        imagePath: ['', Validators.required],
        isFavorite:[false]
      


      }
    );
    this.retrieveAllPhotos();

  }

  AddViewForm():void{
    this.AddClicked = true;
  
  }
  retrieveAllPhotos(): void {
    
    this.galleryService.getAllPhotos()
    .subscribe(
      response => {
        this.imagesList = response;
      },
      error => {
        //console.log(error);
      });
  }
  retrieveAllPhotosAsc(): void {
    
    this.galleryService.getAllPhotosAsc()
    .subscribe(
      response => {
        this.imagesList = response;
      },
      error => {
        //console.log(error);
      });
  }
  // deletePhoto(section) {
  //   this.imageListObj.galleryID=section.galleryID
  //   //console.log(this.imageListObj)
  //   this.delteList.push(this.imageListObj)
  //   this.galleryService.deletePhoto(this.delteList).subscribe((data:any)=>{
  //   this.retrieveAllPhotos();

  // });
  // }
   // deletePhoto(section) {
  //   this.imageListObj.galleryID=section.galleryID
  //   //console.log(this.imageListObj)
  //   this.delteList.push(this.imageListObj)
  //   this.galleryService.deletePhoto(this.delteList).subscribe((data:any)=>{
  //   this.retrieveAllPhotos();

  // });
  // }
   // deletePhoto(section) {
  //   this.imageListObj.galleryID=section.galleryID
  //   //console.log(this.imageListObj)
  //   this.delteList.push(this.imageListObj)
  //   this.galleryService.deletePhoto(this.delteList).subscribe((data:any)=>{
  //   this.retrieveAllPhotos();

  // });
  // }
   // deletePhoto(section) {
  //   this.imageListObj.galleryID=section.galleryID
  //   //console.log(this.imageListObj)
  //   this.delteList.push(this.imageListObj)
  //   this.galleryService.deletePhoto(this.delteList).subscribe((data:any)=>{
  //   this.retrieveAllPhotos();

  // });
  // }
  
   deletePhoto(id) {
    this.galleryService.deleteimage(id).subscribe((data:any)=>{
    this.retrieveAllPhotos();

  });
  }
  addFavorit(id , isfav , image){
    this.galleryService.isFavorit(id , isfav , image).subscribe((data:any)=>{
      this.retrieveAllPhotos();
  
    });
  }
  onSubmit() {
    
      // this.splitList= this.form.value.imagePath.split('\\');
      // this.index = this.form.value.imagePath.split('\\').length;
      // this.form.value.imagePath = "Resourses\\"+this.splitList[2];
      // "imagePath": "E:\\Freelancebb\\DentafdglCenterAPI\\Resourses\\IMG-20211002-WA0002.jpg",

      // {imagePath: 'E:\\Freelance\\DentalCenterAPI\\Resourses\\undefined', isFavorite: false}

    // E:\\Freelance\\DentalCenterAPI\\Resourses\\
    this.imagesListData.push(this.form.value)
    this.galleryService.addPhoto(this.imagesListData).subscribe((data:any)=>{
      this.router.navigate(['/Gallery'])
      .then(() => {
        window.location.reload();
      });
    });

   
  }
  uploadFile= (files) => {
    this.imageService.uploadFile(files).subscribe((data:any)=>{
      this.getbody=data
      this.form.value.imagePath=data.path

      //  this.splitList= this.form.value.imagePath.split('.com/');
      //  this.index = this.form.value.imagePath.split('\\').length;
      //  //console.log(this.splitList[1]);
      //  this.form.value.imagePath=this.splitList[1];
      //  //console.log(this.form.value.imagePath);


    });;
    }
    // public createImgPath = (serverPath: string) => {
    
    // return  this.imageService.createImgPath(serverPath);
    
    // } 
}
