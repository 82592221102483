import { HttpClient, HttpEventType } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '../../../environments/environment';


const baseUrl=environment.baseUrl+'api/v1/files/save';
@Injectable({
  providedIn: 'root'
})
export class UploadImagesService{
  public progress: number;
  public message: string;
  constructor(private http: HttpClient) { }

  @Output() public onUploadFinished = new EventEmitter();


  public uploadFiles = (files) => {
    if (files.length === 0) {
      return;
    }
    
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    

   return this.http.post('https://api.gdcenters.com/api/v1/files/save', formData, {reportProgress: true, observe: 'events'})
      
  }
   public createImgPath = (serverPath: string) => {
    return `https://api.gdcenters.com/api/v1/files/save/${serverPath}`;
  }
 

}
