import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Blog_Model } from 'src/app/Models/Blog_Model';
import { environment } from '../../../environments/environment';


const baseUrl=environment.baseUrl+'admin/v1/blog';

@Injectable({
  providedIn: 'root'
})
export class BlogsService {

  constructor(private http: HttpClient) { }

  ngOnInit() {          
}
addBlog(blog : Blog_Model): Observable<any> 
{
  return this.http.post(baseUrl,blog);
} 
getAllBlogs(): Observable<any> {
  return this.http.get<any>(baseUrl);
}
getBlogByID(id:any): Observable<any> {
  return this.http.get(`${baseUrl}/${id}`);
}
updateBlog(id:any,blog:any): Observable<any> {
  return this.http.put(`${baseUrl}/${id}`,blog);
}
deleteBlog(id:any): Observable<any> {
  return this.http.delete(`${baseUrl}/${id}`);
}




}
