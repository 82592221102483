import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Admin_Model } from 'src/app/Models/Admin_Model';
import { QA_Model } from 'src/app/Models/QA_Model';
import { environment } from '../../../environments/environment';


const baseUrl=environment.baseUrl+'admin/v1/commonquestion'
@Injectable({
  providedIn: 'root'
})
export class QAService {

  constructor(private http: HttpClient) { }

  ngOnInit() {          
}
addQA(qa : QA_Model): Observable<any> 
{
  return this.http.post(baseUrl,qa);
} 
// {{URL}}/admin/v1/commonquestion?type=all&search=
getAllQABy(): Observable<any> {
  return this.http.get<any>(`${baseUrl}?type=all`);
}
getAllQAByType(type:any): Observable<any> {

  return this.http.get<any>(`${baseUrl}?type=${type}`);
}
getAllQAByTypeWithSearch( result:string): Observable<any> {
  return this.http.get<any>(`${baseUrl}?type=all&search=${result}`);
}
getQAyID(id:any): Observable<any> {
  return this.http.get(`${baseUrl}/${id}`);
}
updateQA(id:any,qa:any): Observable<any> {
  return this.http.put(`${baseUrl}/${id}`,qa);
}
deleteQA(id:any): Observable<any> {
  return this.http.delete(`${baseUrl}/${id}`);
}




}


