import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from './ServicesAPis/Authorize/token-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'DentalCenterAdminDashboard';
  public isCollapsed = false;
  private roles: string[] = [];
  isLoggedIn = false;
  showside = false;
  username?: string;

  constructor(private tokenStorageService: TokenStorageService,private router: Router,) { }
  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      // const user = this.tokenStorageService.getUser();
      // this.roles = user.roles;

      this.showside=true;
      // this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      // this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');

      // this.username = user.username;
    }
  }
  logout(): void {
    this.tokenStorageService.signOut();
      
    window.location.reload();
    this.router.navigate(['/login'])
  

  }

}
