import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Admin_Model } from 'src/app/Models/Admin_Model';
import { Vedio_Model } from 'src/app/Models/Vedio_Model';
import { environment } from '../../../environments/environment';


const baseUrl=environment.baseUrl+'admin/v1/happypatientstype/video/happypatients';
const baseUrlDelete=environment.baseUrl+'admin/v1/happypatient';
const baseUrlGetAll=environment.baseUrl+'admin/v1/happypatientstype/video/happypatients?sortby=insertdate&orderbyasc=false';
const baseUrlGetAllASC=environment.baseUrl+'admin/v1/happypatientstype/video/happypatients?sortby=insertdate&orderbyasc=true';



@Injectable({
  providedIn: 'root'
})
export class VedioService {

  constructor(private http: HttpClient) { }

  ngOnInit() {          
}
getAllVedios(): Observable<any> {
  return this.http.get<any>(baseUrlGetAll);
}
getAllVediosASC(): Observable<any> {
  return this.http.get<any>(baseUrlGetAllASC);
}
addVedio(path : Vedio_Model[]): Observable<any> 
{
  return this.http.post(baseUrl,path);
} 

deleteVedio(id:any): Observable<any> {
  return this.http.delete(`${baseUrlDelete}/${id}`);
}


}
