<div class="container-fluid mt-5"  *ngIf="showside">
    <div class="row dash-row">
        <div class="col-xl-3">
            <div class="stats stats-primary">
                <h3 class="stats-title" style="text-align: center;" > الفروع </h3>
                <div class="stats-content">
                    <div class="stats-icon">
                        <a class="btn" (click)="editBranches()"> <i class="fas fa-edit"></i></a>
                    </div>
                    <div class="stats-data">
                        <div class="stats-number">{{countSection.branchesCount}}</div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3">
            <div class="stats stats-primary">
                <h3 class="stats-title" style="text-align: center;" > الأطباء </h3>
                <div class="stats-content">
                    <div class="stats-icon">
                        <a class="btn" (click)="editBranches()">  <i class="fas fa-edit"></i></a>
                    </div>
                    <div class="stats-data">
                        <div class="stats-number">{{countSection.doctorsCount}}</div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3">
            <div class="stats stats-primary">
                <h3 class="stats-title" style="text-align: center;" >سنين الخبره </h3>
                <div class="stats-content">
                    <div class="stats-icon">
                        <a class="btn" (click)="editBranches()"> <i class="fas fa-edit"></i></a>
                    </div>
                    <div class="stats-data">
                        <div class="stats-number">{{countSection.expYearsCount}}</div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3">
            <div class="stats stats-primary">
                <h3 class="stats-title" style="text-align: center;" > مرضانا السعداء </h3>
                <div class="stats-content">
                    <div class="stats-icon">
                        <a class="btn" (click)="editBranches()">  <i class="fas fa-edit"></i></a>
                    </div>
                    <div class="stats-data">
                        <div class="stats-number">{{countSection.happypatientsCount}}+</div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
       <!-- Edit Branch-->
       <div class="col-xl-6" id="branch"  [className]="branchClicked ? 'show' : 'hide'">
        <div class="card easion-card">
            <div class="card-header">
                <div class="easion-card-icon">
                    <i class="fas fa-chart-bar"></i>
                </div>
                <div class="easion-card-title"> Edit Branches</div>
            </div>
            <div class="card-body ">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label> الأطباء</label>
                        <input
                          type="number"
                          formControlName="doctorsCount"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.doctorsCount.errors }"
    
                        />
                        <div *ngIf="submitted && f.doctorsCount.errors" class="invalid-feedback">
                          <div *ngIf="f.doctorsCount.errors.required"> مطلوب</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label> المرضى</label>
                        <input
                          type="number"
                          formControlName="happypatientsCount"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.happypatientsCount.errors }"
    
                        />
                        <div *ngIf="submitted && f.happypatientsCount.errors" class="invalid-feedback">
                          <div *ngIf="f.happypatientsCount.errors.required"> مطلوب</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label> الفروع</label>
                        <input
                          type="number"
                          formControlName="branchesCount"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.branchesCount.errors }"
    
                        />
                        <div *ngIf="submitted && f.branchesCount.errors" class="invalid-feedback">
                          <div *ngIf="f.branchesCount.errors.required"> مطلوب</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label> سنين الخبره</label>
                        <input
                          type="number"
                          formControlName="expYearsCount"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.expYearsCount.errors }"
    
                        />
                        <div *ngIf="submitted && f.expYearsCount.errors" class="invalid-feedback">
                          <div *ngIf="f.expYearsCount.errors.required"> مطلوب</div>
                        </div>
                      </div>
                    <button type="submit" class="btn btn-primary mb-2">Edit</button>
                </form>
            </div>
        </div>
       
       </div>
       <!-- End -->
       
    </div>
</div>


<!-- Add validation to edit

1- the input not empty
2- only number

-->