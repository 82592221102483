import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Status_Model } from 'src/app/Models/Status_Model';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';
import { StatusService } from 'src/app/ServicesAPis/Status/status.service';

@Component({
  selector: 'app-beforeandafteredition',
  templateUrl: './beforeandafteredition.component.html',
  styleUrls: ['./beforeandafteredition.component.css']
})
export class BeforeandaftereditionComponent implements OnInit{

  isAddMode: boolean;
  id:string;
  form :FormGroup;
  submitted = false;
  isLoggedIn = false;
  showside = false;
  baforePath:string;
  afterPath:string
  imagesList:any[]=[];
  statusListData:Status_Model[]=[];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService ,
    private statusService : StatusService,
    private  imageService:UploadImageService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
    this.form = this.formBuilder.group(
      {
        beforeImagePath: [Validators.required],
        afterImagePath: [Validators.required],
        description: [' ',Validators.required]
      
      }
    );
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    if (!this.isAddMode) {

      this.form.get('beforeImagePath').setValue(File, {emitModelToViewChange: false});
      this.form.get('afterImagePath').setValue(File, {emitModelToViewChange: false});
      this.form.patchValue(this.statusService.getsection());  
      

      
  }
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.isAddMode) {

      this.creatStatus();

  } else {
      this.updateStatus();
  } 
  
 }
 private creatStatus():void{
    
  this.form.value.beforeImagePath=this.baforePath
  this.form.value.afterImagePath=this.afterPath

  this.statusListData.push(this.form.value)
  this.statusService.addStatus(this.statusListData).subscribe((data:any)=>{
    
    this.router.navigate(['/Before-After']);
    
  });

}
private updateStatus():void{
  
  this.statusService.updateStatus(this.id,this.form.value).subscribe((data:any)=>{
    
    this.router.navigate(['/Before-After']);
   
  });
}
uploadFileBefore= (files) => {
  
  this.imageService.uploadFile(files).subscribe((data:any)=>{
   
    this.form.value.beforeImagePath=data.path
this.baforePath=data.path
      
    
    


  });
  }
  uploadFileAfter= (files) => {
    
    this.imageService.uploadFile(files).subscribe((data:any)=>{
     
      this.form.value.afterImagePath=data.path
      this.afterPath=data.path
      

        
    
  
    });
    }
}
