<div class="row mt-5" style="justify-content: center; " *ngIf="showside">
    <div class="col-xl-8 ">
        <div class="card easion-card" *ngIf="!isAddMode">
          <div class="card-header">
            <div class="easion-card-icon">
                <i class="fas fa-chart-bar"></i>
            </div>
            <div class="easion-card-title">Edit</div>
        

        </div>
            <div class="card-body ">
                
                <div class="register-form">
                  <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label> : صور الحاله قبل   </label>
                        <br>
                        <input id="upload" type="file"   class="form-control border-0" #file type="file" 
                        (change)="uploadFileBefore(file.files)"  required 
                        formControlName="beforeImagePath"
                    /> 
                        <div *ngIf="submitted && f.beforeImagePath.errors" class="invalid-feedback">
                          <div *ngIf="f.beforeImagePath.errors.required">Image is Required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label> : صور الحاله يعد   </label>
                        <br>
                        <input id="upload" type="file"   class="form-control border-0" #file2  
                        (change)="uploadFileAfter(file2.files)"  required 
                        formControlName="afterImagePath"
                    /> 
                        <div *ngIf="submitted && f.afterImagePath.errors" class="invalid-feedback">
                          <div *ngIf="f.afterImagePath.errors.required">Image is Required</div>
                        </div>
                      </div>
                   
                  <div class="form-group">
                    <label>الوصف </label>
                    <input
                      type="text"
                      formControlName="description"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.description.errors }"

                    />
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                      <div *ngIf="f.description.errors.required">الوصف مطلوب</div>
                    </div>
                  </div>
            
                  <div class="form-group">
                    <button type="submit" class="btn btn-primary mr-5">Update</button>
                
                  </div>
                </form>
                  </div>
            </div>
        </div>
    
        <div class="card easion-card" *ngIf="isAddMode">
            <div class="card-header">
              <div class="easion-card-icon">
                  <i class="fas fa-chart-bar"></i>
              </div>
              <div class="easion-card-title">Add</div>
          
  
          </div>
              <div class="card-body ">
                  
                  <div class="register-form">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                      <div class="form-group">
                          <label> : صور الحاله قبل   </label>
                          <br>
                          <input id="upload" type="file"   class="form-control border-0" #file type="file" 
                          (change)="uploadFileBefore(file.files)"  required 
                          formControlName="beforeImagePath"
                      /> 
                          <div *ngIf="submitted && f.beforeImagePath.errors" class="invalid-feedback">
                            <div *ngIf="f.beforeImagePath.errors.required">Image is Required</div>
                          </div>
                      </div>

                      <div class="form-group">
                          <label> : صور الحاله يعد   </label>
                          <br>
                          <input id="upload" type="file"   class="form-control border-0" #file2  
                          (change)="uploadFileAfter(file2.files)"  required 
                          formControlName="afterImagePath"
                      /> 
                          <div *ngIf="submitted && f.afterImagePath.errors" class="invalid-feedback">
                            <div *ngIf="f.afterImagePath.errors.required">Image is Required</div>
                          </div>
                        </div>
                     
                    <div class="form-group">
                      <label>الوصف </label>
                      <input
                        type="text"
                        formControlName="description"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.description.errors }"

                      />
                      <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                        <div *ngIf="f.description.errors.required">الوصف مطلوب</div>
                      </div>
                    </div>
              
                    <div class="form-group">
                      <button type="submit" class="btn btn-primary mr-5">Add</button>
                  
                    </div>
                  </form>
                    </div>
              </div>
        </div>
    </div>

  

  
 
</div>
