<!-- <div class="dash">
  
  <app-navbar></app-navbar>
  <div class="dash-app">
    <header class="dash-toolbar" *ngIf="showside" >
      
        <div class="tools">
          
            <div class="dropdown tools-item">
              <a href="#" class="" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-bell" style="color: aliceblue;"></i>
                <i class="tools-item-count">4</i>                </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                <a class="dropdown-item" href="#!">new reservation <i class="fas fa-ellipsis-h" aria-hidden="true"></i> </a> 
                <a class="dropdown-item" href="#!">new reservation <i class="fas fa-ellipsis-h" aria-hidden="true"></i> </a> 
                <a class="dropdown-item" href="#!">new reservation <i class="fas fa-ellipsis-h" aria-hidden="true"></i> </a> 

              </div>
          </div>
            <div class="dropdown tools-item">
                <a href="#" class="" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-user" style="color: aliceblue;"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                    <a class="dropdown-item" href="#!">Profile</a>
                    <a class="dropdown-item" href="#!">Admin</a>

                    <a class="btn dropdown-item" href="login.html" (click)="logout()">Logout</a>
                </div>
            </div>
        </div>
    </header>
  <router-outlet></router-outlet>
  </div>
  
</div> -->
<div class="dash">
  
  <app-navbar></app-navbar>
  <div class="dash-app">
    <header class="dash-toolbar" *ngIf="showside" >
      
        <div class="tools">
          
            <!-- <div class="dropdown tools-item">
              <a href="#" class="" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-bell" style="color: aliceblue;"></i>
                <i class="tools-item-count">4</i>                </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                <a class="dropdown-item" href="#!">new reservation <i class="fas fa-ellipsis-h" aria-hidden="true"></i> </a> 
                <a class="dropdown-item" href="#!">new reservation <i class="fas fa-ellipsis-h" aria-hidden="true"></i> </a> 
                <a class="dropdown-item" href="#!">new reservation <i class="fas fa-ellipsis-h" aria-hidden="true"></i> </a> 

              </div>
          </div> -->
            <div class="dropdown tools-item">
                <a href="#" class="" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-user" style="color: aliceblue;"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                    <a class="dropdown-item" routerLink="/Admin">Admin</a>

                    <a class="btn dropdown-item" href="login.html" (click)="logout()">Logout</a>
                </div>
            </div>
        </div>
    </header>
  <router-outlet></router-outlet>
  </div>
  
</div>
