<div class="row mt-5" style="justify-content: center; text-align: right;"*ngIf="showside">
    <div class="col-lg-6">
      <div class="card easion-card">
        <div class="card-header">
          <div class="easion-card-icon">
            <i class="fas fa-table"></i>
          </div>
          <div class="easion-card-title">Services</div>
        </div>
        <div class="card-body ">
          <div class="search-box" style="width:inherit">
                            
            <input  type="text" class="form-control"
             placeholder="Search with name"
             id="speciesNameSearch"
              name="speciesNameSearch" 
            (keyup.enter)="getSearchResult()"
             [(ngModel)]="speciesName">
            <a class="btn primary">
                <i class="fa fa-search" aria-hidden="true"></i>
            </a>
        </div>
          <table class="table table-hover table-in-card">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of serviceList let i= index">
                <td> {{i+1}} </td>
                <td> {{item.name}} </td>
                <td>
                  <a  [routerLink]="['/Service-Edition', item.serviceID]" class="btn btn-primary  mb-1">
                    <i class="fas fa-edit    ">
                      edit
                    </i>
                  </a>
                </td>
              </tr>
             
            
            
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  