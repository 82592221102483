import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { env } from 'process';
import { Observable } from 'rxjs';
import { Reviews_Model } from 'src/app/Models/Reviews_Model';

import { environment } from '../../../environments/environment';
const baseUrl=environment.baseUrl+'admin/v1/happypatientstype/review/happypatient'
const baseUrlGetAll=environment.baseUrl+'admin/v1/happypatientstype/review/happypatients?sortby=insertdate&orderbyasc=false';
const baseUrlGetAllASC=environment.baseUrl+'admin/v1/happypatientstype/review/happypatients?sortby=insertdate&orderbyasc=true';
const baseUrlFavGetAll=environment.baseUrl+'admin/v1/happypatientstype/review/happypatients?sortby=isfavorite&orderbyasc=false';
const baseUrlFavGetAllASC=environment.baseUrl+'admin/v1/happypatientstype/review/happypatients?sortby=isfavorite&orderbyasc=true';
const baseUrlPost=environment.baseUrl+'admin/v1/happypatientstype/review/happypatients';


@Injectable({
  providedIn: 'root'
})
export class RewiewService {

  constructor(private http: HttpClient) { }

  ngOnInit() {          
}
getAllReviews(): Observable<any> {
  return this.http.get<any>(baseUrlGetAll);
}
getSearchResult(result:any):Observable<any> {
  return this.http.get<any>(`${environment.baseUrl}admin/v1/happypatientstype/review/happypatients?sortby=insertdate&orderbyasc=true&search=${result}`);

}
getAllReviewsASC(): Observable<any> {
  return this.http.get<any>(baseUrlGetAllASC);
}
getAllReviewsIsFav(): Observable<any> {
  return this.http.get<any>(baseUrlFavGetAll);
}
getAllReviewsIsFavASC(): Observable<any> {
  return this.http.get<any>(baseUrlFavGetAllASC);
}
addReview(path : Reviews_Model[]): Observable<any> 
{
  return this.http.post(baseUrlPost,path);
} 
updateReview(id:any,review:any): Observable<any> {
  return this.http.put(`${baseUrl}/${id}`,review);
}
getReviewByID(id:any): Observable<any> {
  return this.http.get(`${baseUrl}/${id}`);
}
isFavorit(id:any , isfav:any ,review:any): Observable<any> {
  return this.http.put(`${environment.baseUrl}admin/v1/happypatient/${id}/favorite/${isfav}`,review);
}
// {{URL}}/admin/v1/happypatient/{{happypatientid}}/favorite/{{isfavorite}}
// {{URL}}/admin/v1/happypatient/{{happypatientid}}
deleteReview(id:any): Observable<any> {
  return this.http.delete(`${environment.baseUrl}admin/v1/happypatient/${id}`);
}


}
