import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';
import { RewiewService } from 'src/app/ServicesAPis/HappyPatient/rewiew.service';
import { DoctorService } from 'src/app/ServicesAPis/Doctor/doctor.service';
import { Doctor_Model } from 'src/app/Models/Doctor_Model';


@Component({
  selector: 'app-get-doctor-by-id',
  templateUrl: './get-doctor-by-id.component.html',
  styleUrls: ['./get-doctor-by-id.component.css']
})
export class GetDoctorByIDComponent implements OnInit{
  isAddMode: boolean;
  id:string;
  form :FormGroup;
  submitted = false;
  isLoggedIn = false;
  showside = false;
  section:Doctor_Model;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService ,
    private doctorService : DoctorService,
    private  imageService:UploadImageService
  ) {}


  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
    this.form = this.formBuilder.group(
      {
        name: ['', Validators.required],
        firstDiscription: [
          '',
          [
            Validators.required,
            Validators.minLength(190),
            Validators.maxLength(300)
          ]
        ],
        secondDiscription: [
          '',
          [
            Validators.required,
            Validators.minLength(190),
            Validators.maxLength(300)
          ]
        ],
        thirdDiscription: [
          '',
          [
            Validators.required,
            Validators.minLength(190),
            Validators.maxLength(288)
          ]
        ],
        referalNumber: ['', Validators.required],
        homeImagePath: [Validators.required],
        personalImagePath: [Validators.required],
        exploreImagePath: [Validators.required],
        videoPath: ['',Validators.required],
        tiktokURL: ['',Validators.required],
        facebookURL: ['',Validators.required],
        instgramURL: ['',Validators.required],
        linkedInURL: ['',Validators.required],
        // homeDisplay: [false,Validators.required],


      }
    );
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    if (!this.isAddMode) {
      this.form.get('homeImagePath').setValue(File, {emitModelToViewChange: false});
      this.form.get('personalImagePath').setValue(File, {emitModelToViewChange: false});
      this.form.get('exploreImagePath').setValue(File, {emitModelToViewChange: false});

        this.doctorService.getDoctorByID(this.id)
            .subscribe(x => {
               this.section=x;
               
              this.form.patchValue(x);  
          });
      
  }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.isAddMode) {

      this.creatDoctor();

  } else {
      this.updateDoctor();
  } 
 }
 private creatDoctor():void{

  this.doctorService.addDoctor(this.form.value).subscribe((data:any)=>{
    this.router.navigate(['/Doctors']);
    
  });

}
private updateDoctor():void{
  this.doctorService.updateDoctor(this.id,this.form.value).subscribe((data:any)=>{
    this.router.navigate(['/Doctors']);
   
  });
}
uploadFile1= (files) => {
  this.imageService.uploadFile(files).subscribe((data:any)=>{
    this.form.value.imagePath=data.path



  });
  }
uploadFile2= (files) => {
    this.imageService.uploadFile(files).subscribe((data:any)=>{
      this.form.value.imagePath=data.path
  
  
  
    });
    }
uploadFile3= (files) => {
      this.imageService.uploadFile(files).subscribe((data:any)=>{
        this.form.value.imagePath=data.path
    
    
    
      });
      }
}