import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { RewiewService } from 'src/app/ServicesAPis/HappyPatient/rewiew.service';
import { QAService } from 'src/app/ServicesAPis/QA/qa.service';

@Component({
  selector: 'app-qaedition',
  templateUrl: './qaedition.component.html',
  styleUrls: ['./qaedition.component.css']
})
export class QAEditionComponent implements OnInit {

  isAddMode: boolean;
  id:string;
  form :FormGroup;
  submitted = false;
  isLoggedIn = false;

  showside = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService ,
    private qaService : QAService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
    this.form = this.formBuilder.group(
      {
        question: ['', Validators.required],
        answer: ['', Validators.required],
        type: ['', Validators.required],

       
      }
    );
  
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    // Get the data by Id
    if (!this.isAddMode) {
      this.qaService.getQAyID(this.id)
          .pipe()
          .subscribe(x => this.form.patchValue(x));
  }
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.isAddMode) {

      this.creatQA();

  } else {
      this.updateQA();
  } 
 }
  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }
  private creatQA():void{

    this.qaService.addQA(this.form.value).subscribe((data:any)=>{
      //console.log(data)
      this.router.navigate(['/Questions']);
      
    });

  }
  private updateQA():void{
    this.qaService.updateQA(this.id,this.form.value).subscribe((data:any)=>{
      this.router.navigate(['/Questions']);
     
    });
  }

}
