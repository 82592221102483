import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Doctor_Model } from 'src/app/Models/Doctor_Model';
import { Gallery_Model } from 'src/app/Models/Gallery_Model';
import { environment } from '../../../environments/environment';


//orderbyasc -->true / false default : true
//sortby --> Name, ReferalNumber default : name
//search --> Name, ReferalNumber default : ' '

const baseUrl=environment.baseUrl+'admin/v1/doctor';
@Injectable({
  providedIn: 'root'
})
export class DoctorService  {

  constructor(private http: HttpClient) { }

  ngOnInit() {          
}
 
getAllDoctors(): Observable<any> {
  return this.http.get<any>(baseUrl+'?orderbyasc=false&sortby=name');
}
getAllDoctorsASC(): Observable<any> {
  return this.http.get<any>(baseUrl+'?orderbyasc=true&sortby=name');
}
getAllDoctorsNum(): Observable<any> {
  return this.http.get<any>(baseUrl+'?orderbyasc=false&sortby=referalNumber');
}
getAllDoctorsASCNum(): Observable<any> {
  return this.http.get<any>(baseUrl+'?orderbyasc=true&sortby=referalNumber');
}
getSearchResultDoctors(ressult:any): Observable<any> {
  return this.http.get<any>(baseUrl+'?orderbyasc=true&sortby=name&search='+ressult);
}
getAllDoctorsForDropDown(): Observable<any> {
  return this.http.get<any>(baseUrl+'/dropdown');
}

getDoctorByID(id:any): Observable<any> {
  return this.http.get(`${baseUrl}/${id}`);
}

addDoctor(doctor : Doctor_Model)
{
  return this.http.post(baseUrl,doctor);

}
updateDoctor(id:string,doctor:Doctor_Model){
  return this.http.put(`${baseUrl}/${id}`,doctor);
}
deleteDoctor(id:any): Observable<any> {
  return this.http.delete(`${baseUrl}/${id}`);
}




}

