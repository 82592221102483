import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { FamouseService } from 'src/app/ServicesAPis/Famouse/famouse.service';
import { VedioService } from 'src/app/ServicesAPis/HappyPatient/vedio.service';

@Component({
  selector: 'app-all-celebrities',
  templateUrl: './all-celebrities.component.html',
  styleUrls: ['./all-celebrities.component.css']
})
export class AllCelebritiesComponent implements OnInit {
  AddClicked: Boolean = false;
  speciesName: string = "";
  famouseList:any[]=[];
  showside = false;
  isLoggedIn = false;
  constructor(
    private router: Router,
    private tokenStorageService: TokenStorageService ,  
    private famouseService: FamouseService,
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
     
      this.showside=true;
      
    }
    else{
      this.router.navigate(['/login']);

    }
    this.retrieveAllFamouse();

  }
  retrieveAllFamouse(){
    this.famouseService.getAllFamouse()
    .subscribe(
      response => {
        this.famouseList = response;
       
      },
      error => {
        //console.log(error);
      });
  }
  getSearchResult() {

    this.famouseService.getResult(this.speciesName).subscribe(response => {
      this.famouseList = response;
    
    });
  }
  deleteitem(id:any){
    this.famouseService.deleteFamouse(id).subscribe((data)=>{
    this.retrieveAllFamouse();
    });

  }
}
