import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { BlogsService } from 'src/app/ServicesAPis/Blogs/blogs.service';
import { PatientsService } from 'src/app/ServicesAPis/Doctor/patients.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {
  isLoggedIn = false;
  id:string;
  isAddMode: boolean;

  showside = false;
  blogList:any[]=[]
  constructor(
    private tokenStorageService: TokenStorageService ,  
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,     
    private router: Router,
    private blogService: BlogsService,
    private  imageService:UploadImageService
  ) { }

  ngOnInit(): void {
    
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
 
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    if (!this.isAddMode) {
      this.retrieveAllBlogsForDoctor();

  }
  else{
    this.retrieveAllBlogs();

  }

  }
  retrieveAllBlogs(): void {
    
    this.blogService.getAllBlogs()
    .subscribe(
      response => {
        this.blogList = response;
      
      },
      error => {
        //console.log(error);
      });
  }
  deleteBlog(id) {
    this.blogService.deleteBlog(id).subscribe((data:any)=>{
    this.retrieveAllBlogs();

  });
  }
  retrieveAllBlogsForDoctor():void{
    this.blogService.getAllBlogs()
    .subscribe(
      response => {
        response.forEach(element => {
          if(element.doctorID==this.id){
            this.blogList.push(element) ;

          }
        });
      
      },
      error => {
        //console.log(error);
      });
  }
}
