import { Component, ElementRef, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/ServicesAPis/Authorize/token-storage.service';
import { BlogsService } from 'src/app/ServicesAPis/Blogs/blogs.service';
import { DoctorService } from 'src/app/ServicesAPis/Doctor/doctor.service';
import { UploadImageService } from 'src/app/ServicesAPis/files/upload-image.service';


@Component({
  selector: 'app-blog-edition',
  templateUrl: './blog-edition.component.html',
  styleUrls: ['./blog-edition.component.css']
})
export class BlogEditionComponent implements OnInit {
  
  splitList:any[]=[]

  isAddMode: boolean;
  id:string;
  submitted = false;
  blogForm: FormGroup;
  blogDetails: FormArray;
  isLoggedIn = false;
  showside = false;
  doctorList:any[]=[];
  pathlist:any[]=[];
  path1:string;
  path2:string;
  
  @ViewChild('myInput') 
myInputVariable: ElementRef;
  constructor(
    private tokenStorageService: TokenStorageService ,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private blogServices:BlogsService,
    private doctorService : DoctorService,
    private  imageService:UploadImageService


  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {


      this.showside=true;

    }
    else{
      this.router.navigate(['/login']);

    }
    this.blogForm = this.formBuilder.group(
      {
        title: ['', Validators.required],
        writter: ['', Validators.required],
        imagePath : ['', Validators.required],
        doctorID : [''],
        blogDetails: this.formBuilder.array([ this.createDetails() ])


      }
    );
  
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    if (!this.isAddMode) {
      this.blogForm.get('imagePath').setValue(File, {emitModelToViewChange: false});

      this.blogServices.getBlogByID(this.id)
          .pipe()
          .subscribe(x => this.blogForm.patchValue(x));
  }
  this.getDoctorMenue()

  }
//   onSubmit(): void {
//     this.submitted = true;

  

//     if (this.blogForm.invalid) {
//       return;
//     }

//     if (this.isAddMode) {
//       alert("here")
//       this.creatBlog();

//   } else {
//     alert("here update")

//       this.updateBlog();
//   } 
//  }
  onSubmit():void{
  //  alert("here create");
  this.blogForm.value.imagePath=this.path1;
  //console.log(this.blogForm.value)
  this.blogServices.addBlog(this.blogForm.value).subscribe((data:any)=>{
    // alert("added")
    this.router.navigate(['/All-Blogs']);
    
  });
 }
  createDetails(): FormGroup {
    return this.formBuilder.group({
      title: '',
      discreption: '',
      imagePath: '',
      detailsOrder:0,
      
    });
  }
  addSection(): void {
    this.blogDetails = this.blogForm.get('blogDetails') as FormArray;
    this.blogDetails.push(this.createDetails());
  }
  getDoctorMenue():void{
    this.doctorService.getAllDoctorsForDropDown()
    .subscribe(
      response => {
        this.doctorList = response;
      },
      error => {
        //console.log(error);
      });
   }
   get doctorID() {
    return this.blogForm.get('doctorID');
  }
  get imagePath() {
    return this.blogForm.get('blogDetails');
  }
   changeDoctor(e) {
    this.splitList=e.target.value.split(': ');
    this.doctorID.setValue(this.splitList[1])
  }
   private updateBlog():void{
    this.blogServices.updateBlog(this.id,this.blogForm.value).subscribe((data:any)=>{
      this.router.navigate(['/All-Blogs']);
  
     
    });
   }
  get f(): { [key: string]: AbstractControl } {
    return this.blogForm.controls;
  }
  uploadFile1= (files) => {
    this.imageService.uploadFile(files).subscribe((data:any)=>{
     this.path1=data.path;
      this.blogForm.value.imagePath=data.path
  
  
    });
  }

  getval:any
  uploadFile2= (files , i) => {
   

      this.imageService.uploadFile(files).subscribe((data:any)=>{
        
        this.imagePath.value[i].imagePath=data.path

        



        // //console.log(this.imagePath[i])
        // this.imagePath[i].imagePath=data.path
        // //console.log(this.imagePath[i].imagePath)

        // this.blogForm.value.imagePath=data.path
        // //console.log(this.blogForm.value.imagePath)  
    
    
      });
    }


}
