import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Admin_Model } from 'src/app/Models/Admin_Model';
import { environment } from '../../../environments/environment';


const baseUrl=environment.baseUrl+'admin/v1/homecounter';

@Injectable({
  providedIn: 'root'
})
export class CounterService {

  constructor(private http: HttpClient) { }

  ngOnInit() {          
}
 
getAllCounters(): Observable<any> {
  return this.http.get<any>(baseUrl);
}


updateCounters(id:any,nums:any): Observable<any> {
  return this.http.put(`${baseUrl}/${id}`,nums);
}



}
