<div class="row mt-5" style="justify-content: center; " *ngIf="showside">
    <div class="col-xl-12 ">
  
        <div class="card easion-card">
            <div class="container-xl">
                <div class="table-responsive">
                    <div class="table-wrapper">
                        <div class="table-title">
                            <div class="row">
                                <div class="col-sm-8"><h2>Admins <b>Details</b></h2></div>
                                <div class="col-sm-4">
                                    <a type="button" class="btn btn-info add-new" href="/Admin_Edition"><i class="fa fa-plus"></i> Add New</a>
                                </div>
                                <!-- <div class="col-sm-4">
                                    <div class="search-box">
                                        <i class="material-icons">&#xE8B6;</i>
                                        <input type="text" class="form-control" placeholder="Search&hellip;">
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <!-- <th>Name <i class="fa fa-sort"></i></th> -->
                                   <th>Name</th>

                                    <th>Email</th>
                                    
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let admin of adminList let i= index">
                                    <td>{{i+1}}</td>
                                    <td>{{admin.userName}}</td>
                                    <td>{{admin.email}}</td>
                                   
                                    <td>
                                        <!-- <a href="#" class="view" title="View" data-toggle="tooltip"><i class="material-icons">&#xE417;</i></a> -->
                                        <a [routerLink]="['/Admin_Edition', admin.adminID]" class="edit" title="Edit" data-toggle="tooltip"><i class="material-icons">&#xE254;</i></a>
                                        <a  class="btn" (click)="deleteAdmin(admin.adminID)" class="delete" title="Delete" data-toggle="tooltip"><i class="material-icons">&#xE872;</i></a>
                                    </td>
                                </tr>
                                  
                            </tbody>
                        </table>
                        <!-- <div class="clearfix">
                            <div class="hint-text">Showing <b>5</b> out of <b>25</b> entries</div>
                            <ul class="pagination">
                                <li class="page-item disabled"><a href="#"><i class="fa fa-angle-double-left"></i></a></li>
                                <li class="page-item"><a href="#" class="page-link">1</a></li>
                                <li class="page-item"><a href="#" class="page-link">2</a></li>
                                <li class="page-item active"><a href="#" class="page-link">3</a></li>
                                <li class="page-item"><a href="#" class="page-link">4</a></li>
                                <li class="page-item"><a href="#" class="page-link">5</a></li>
                                <li class="page-item"><a href="#" class="page-link"><i class="fa fa-angle-double-right"></i></a></li>
                            </ul>
                        </div> -->
                    </div>
                </div>  
            </div> 
        </div>
  
  
  </div>
  </div>
  
  
  
  
  
  
  