<div class="row mt-5" style="justify-content: center; text-align: right;">
    <div class="col-xl-8 ">

        <div class="card easion-card">
            <div class="card-header">
                <div class="easion-card-icon">
                    <i class="fas fa-table"></i>
                </div>
                <div class="easion-card-title" style="float: left;">Articles</div>
               
                   
                    <a href="/Article-Editions" class="btn btn-primary " style="margin-left: auto;"> <i class="fas fa-plus"></i> 
                        Add article
                    </a>
        
            </div>
            <div class="card-body ">
                <table class="table table-hover table-in-card">
                    <thead >
                        <tr >
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
             
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>
                                كيف يتم زراعة الأسنان بالطرق المثالية خلال 3 أيام فقط !
                            </td>
                            <td>   
                               <a href="/Article-Editions" class="btn"> <i class="fas fa-edit"></i></a>
                                <a class="btn"> <i class="fas fa-trash-alt"></i></a>
        
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>
                                كيف يتم زراعة الأسنان بالطرق المثالية خلال 3 أيام فقط !
                            </td>
                            <td>   
                                <a href="/Article-Editions" class="btn"> <i class="fas fa-edit"></i></a>
                                 <a class="btn"> <i class="fas fa-trash-alt"></i></a>
        
                             </td>
                        </tr>
                       
                    </tbody>
                </table>
            </div>
        </div>


</div>
</div>