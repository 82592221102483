import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Gallery_Model } from 'src/app/Models/Gallery_Model';
import { ImageList_Model } from 'src/app/Models/ImageList_Model';
import { environment } from '../../../environments/environment';


const baseUrl=environment.baseUrl
// +'admin/v1/gallerytype/dental/gallery';
@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private http: HttpClient) { }

  ngOnInit() {          
}
addPhoto(photo : Gallery_Model[])
{
  const url=baseUrl+'admin/v1/gallerytype/all/gallery';
  return this.http.post(url,photo);
  // http://mohamed555dsa-001-site1.btempurl.com/admin/v1/gallerytype/all/gallery

} 

getAllPhotos(): Observable<any> {
  return this.http.get<any>(baseUrl+'admin/v1/gallery?search=all&sortby=desc');

}
getAllPhotosAsc(): Observable<any> {
  return this.http.get<any>(baseUrl+'admin/v1/gallery?search=allsortby=asc');
}
deleteimage(id:any): Observable<any> {
  return this.http.delete(`${baseUrl}admin/v1/gallery/${id}`);
}

deletePhoto(photo2 : ImageList_Model[]): Observable<any> {
  const urldel=baseUrl+'admin/v1/gallery';
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    body: {
      photo2
    },
  };
  //console.log(photo2)
  // return this.http.delete<any>(urldel ,{ body: { p} });
  // return this.http.request('delete', urldel, { body: { photo2 } });
return   this.http.delete(urldel,options);

  
}
isFavorit(id:any , isfav:any ,image:any): Observable<any> {
  return this.http.put(`${baseUrl+'admin/v1/gallerytype/all/gallery'}/${id}/${isfav}`,image);
}



}
