<div class="row mt-5" style="justify-content: center; text-align: right;">
    <div class="col-xl-8 ">
      
        <div class="card easion-card">
          <div class="card-header">
            <div class="easion-card-icon">
                <i class="fas fa-chart-bar"></i>
            </div>
            <div class="easion-card-title">Content</div>
     

        </div>
            <div class="card-body ">
                
                <div class="register-form">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                      
                      <div class="form-group">
                        <label> عنوان الخدمة</label>
                        <input
                          type="text"
                          formControlName="name"
                          class="form-control"
                          readonly

                        />
                        
                      </div>
                      
                      <div class="form-group">
                        <label> : عن الخدمة</label>
                        <textarea 
                        type="text"
                        formControlName="info"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.info.errors }"
                        cols="20" rows="5">
                      </textarea>
                     
                        <div *ngIf="submitted && f.info.errors" class="invalid-feedback">
                          <div *ngIf="f.info.errors.required">مطلوب</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label> (اختياري): فقره الثانيه عن الخدمه</label>
                        <textarea 
                        type="text"
                        formControlName="infoPartOne"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.infoPartOne.errors }"
                        cols="20" rows="5">
                      </textarea>
                     
                        <div *ngIf="submitted && f.infoPartOne.errors" class="invalid-feedback">
                          <div *ngIf="f.infoPartOne.errors.required">مطلوب</div>
                        </div>
                      </div>
                     <div class="alert alert-danger" role="alert">
                     
                      اضافه فقط في احدى الخانتين  اختر اما اضافه فيديو او صوره !
                      اجباري اضافه احد الخيارين فقط
                     </div>
                      <div class="form-group">
                        <label> (اختياري)  : لينك الفيديو التعريفي الخاص بالخدمة</label>
                        <input
                          type="text"
                          formControlName="videoPath"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.videoPath.errors }"

                        />
                        <div *ngIf="submitted && f.videoPath.errors" class="invalid-feedback">
                          <div *ngIf="f.videoPath.errors.required">اللينك مطلوب</div>
                        </div>
                      </div>
                      
                         
                      <div class="form-group">
                        <label> (اختياري) : صوره خاصه بالخدمه</label>
                        <input id="upload" type="file"   class="form-control border-0" #file type="file" 
                    (change)="uploadFile(file.files)"  required 
                    formControlName="infoPartTwo"
                /> 
                    <div *ngIf="submitted && f.infoPartTwo.errors" class="invalid-feedback">
                      <div *ngIf="f.infoPartTwo.errors.required">Image is Required</div>
                    </div>
                      </div>
                     <img [src]="imgsection" width="100px"> 
                      <div class="form-group mt-5">
                        <button type="submit" class="btn btn-primary mr-5">Update</button>
                   
                      </div>
                    </form>
                  </div>
            </div>
        </div>
    
       
    </div>

  

  
 
</div>
